import { Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  fieldValue: {
    flex: 1,
  },
  inlineDiv: {
    display: "inline",
    flex: 1,
  },
  fieldLabel: {
    color: theme.palette.text.secondary,
  },
  noValue: {
    color: theme.palette.text.disabled,
  },
  errorHelperText: {
    color: theme.palette.error.main,
  },
  fieldValueTruncated: {
    flex: 1,
    "&, & > *": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  fieldValueBreakAll: {
    flex: 1,
    "&, & > *": {
      wordBreak: "break-word",
    },
  },
}));

const TabularField = ({
  label,
  value,
  noValue = "no value",
  noValueCompare = ["", null],
  labelGridSize = 4,
  variant = "body2",
  direction = "row",
  alignItems = "baseline",
  overflow = false,
  wordBreak = false,
  error = false,
  helperText = "",
}) => {
  const classes = useStyles();
  const labelProps = labelGridSize === "auto" ? {} : { sm: labelGridSize };
  const fieldValueClass = overflow
    ? classes.fieldValueTruncated
    : wordBreak
    ? classes.fieldValueBreakAll
    : classes.fieldValue;
  const directionProps =
    direction === "row"
      ? { spacing: 1, direction: direction }
      : { direction: direction };

  const gridProps = { ...directionProps, alignItems: alignItems };

  return (
    <Grid container {...gridProps}>
      <Grid item {...labelProps}>
        <Typography variant={variant} className={classes.fieldLabel}>
          {label}:
        </Typography>
      </Grid>
      <Grid item className={fieldValueClass}>
        {noValueCompare.some((x) => x === value) ? (
          <Typography variant={variant} className={classes.noValue}>
            {noValue}
          </Typography>
        ) : typeof value === "string" || value instanceof String ? (
          <Typography variant={variant} display="inline">
            {value}
          </Typography>
        ) : (
          <div className={classes.inlineDiv}>{value}</div>
        )}
        {error && (
          <Typography
            variant="caption"
            className={classes.errorHelperText}
            display="block"
          >
            {helperText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

TabularField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  noValue: PropTypes.any,
  noValueCompare: PropTypes.array,
  labelGridSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["auto"]),
  ]),
  variant: PropTypes.oneOf([
    "body1",
    "body2",
    "button",
    "caption",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "inherit",
    "overline",
    "subtitle1",
    "subtitle2",
  ]),
  direction: PropTypes.oneOf(["row", "column"]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default TabularField;
