import React, { useContext } from "react";

import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import MyDataGrid from "../../../General/Pagination/MyDataGrid";

export default function DepartmentsList(props) {
  const { homeDispatch } = useContext(HomeAppContext);

  const tableOptions = {
    columns: [
      {
        name: "ToSearch",
        description: "To",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 4
      },
      {
        name: "Subject",
        description: "Subject",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedAt",
        description: "Created At",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "SentAt",
        description: "Sent At",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Status",
        description: "Status",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "CreatedAt", order: "-" }],
    additionalFilters: [
      {
        name: "ToSearch",
        description: "To",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 4
      },
      {
        name: "Subject",
        description: "Subject",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 3
      },
      {
        name: "Status",
        description: "Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/system/mail-logs/status/dropdown`,
            multiple: false,
            liveOptions: false
          }
        },
        size: 2
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "System - Mail Logs"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/system/mail-logs"}
            label={"Mail logs"}
            renderItem={(item) => <Entry key={item.id} entry={item} />}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
