import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import ConfirmDialog from "../../../General/ConfirmDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./Form";
import { HomeAppContext } from "../../../Home";
import Slide from "@mui/material/Slide";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  embedImages: [],
  attachments: [],
  executionDate: null,
  toUsers: [],
  bccUsers: [],
  ccUsers: [],
  replyToUsers: [],
  subject: "",
  template: "",
  _originalTemplate: "",
  replyToEmail: ""
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(initialEntry);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    api
      .get(`/api/system/settings`)
      .then((r) => {
        setDirty(false);
        setSubmitted(false);
        setErrors([]);
        setInternalEntry({
          ...initialEntry,
          timezone: r.data.globalTimezone.id
        });
        setLoading(false);
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  }, [homeDispatch, open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseConfirm = () => {
    if (dirty) {
      setConfirmClose(true);
    } else {
      handleClose();
    }
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Creating Notification", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const entryDto = {
      ...internalEntry
    };

    api
      .post(`/api/system/ad-hoc-notifications`, entryDto)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Notification created", type: "success" }
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleCloseConfirm}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-create-entry"
      >
        <DialogTitle id="alert-dialog-create-entry">
          {"New Ad Hoc Notification"}
        </DialogTitle>
        <DialogContent>
          <Form
            entry={internalEntry}
            errors={errors}
            setEntry={setInternalEntry}
            loading={loading}
            submitted={submitted}
            setSaveDisabled={setSaveDisabled}
            onSetDirty={setDirty}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleCloseSave}
            color="primary"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmClose}
        setOpen={setConfirmClose}
        withReconfirm={false}
        title={"Unsaved Changes"}
        message={"Are you sure that you want to close without saving"}
        onCloseSuccess={handleClose}
        onClose={(event) => setConfirmClose(false)}
      />
    </React.Fragment>
  );
}
