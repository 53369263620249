import CustomAutocomplete from "../../../General/CustomAutocomplete";
import Grid from "@mui/material/Grid";
import React from "react";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));


export default function Form({
                               entry = null,
                               errors = [],
                               setEntry,
                               loading,
                               submitted,
                               setSaveDisabled
                             }) {
  const classes = useStyles();

  const onValueChange = (value, property) =>
    setEntry({ ...entry, [property]: value });

  return entry === null ? null : (
    <Grid container className={classes.paper} spacing={2}>
      <Grid item sm={12}>
        <CustomAutocomplete
          value={entry.globalTimezone}
          setValue={(value) => onValueChange(value, "globalTimezone")}
          related={null}
          onLoading={setSaveDisabled}
          url={"/api/system/settings/time-zones/dropdown"}
          label={"Timezone"}
          placeholder={"Select a timezone"}
          noOptionsText={"No timezone found"}
          loadingText={"Searching"}
          margin={"normal"}
          liveOptions={false}
          error={_.isArray(errors.globalTimezone)}
          helperText={
            _.isArray(errors.globalTimezone) && errors.globalTimezone.join()
          }
          disableClearable={true}
        />
      </Grid>
    </Grid>
  );
}
