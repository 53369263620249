import React, { useContext, useEffect, useState } from "react";

import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import MyDataGrid from "../../../General/Pagination/MyDataGrid";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import TimerProgress from "./TimedProgress";

const levels = [
  { id: "Verbose", text: "Verbose" },
  { id: "Debug", text: "Debug" },
  { id: "Information", text: "Information" },
  { id: "Warning", text: "Warning" },
  { id: "Fatal", text: "Fatal" },
  { id: "Error", text: "Error" }
];

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];

const initialState = {
  refreshEvery: 6000,
  refresh: 0,
  autoRefresh: false
};
export default function VendorActionLogList(props) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.autoRefresh) {
        setState((prevState) => ({
          ...prevState,
          refresh: prevState.refresh + 1
        }));
      }
    }, state.refreshEvery);

    return () => clearInterval(interval);
  }, [state.autoRefresh, state.refreshEvery]);

  const onAutoRefreshToggle = () => {
    setState((prevState) => ({
      ...prevState,
      autoRefresh: !prevState.autoRefresh
    }));
  };

  const tableOptions = {
    columns: [
      {
        name: "VendorActionDate",
        description: "Date",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "210px"
      },
      {
        name: "VendorActionResult",
        description: "Result",
        sortable: true,
        align: "center",
        filter: {
          enabled: false
        },
        size: "100px"
      },
      {
        name: "VendorActionDetails",
        description: "Details",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "400px"
      },
      {
        name: "VendorActionUser",
        description: "Atms Username",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "750px"
      }
    ],
    defaultSort: [{ name: "Timestamp", order: "-" }],
    additionalFilters: [
      {
        name: "VendorActionDate",
        description: "Date",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 0,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      },
      {
        name: "VendorActionStatus",
        description: "Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          defaultValue: [{ id: "Success" }, { id: "Error" }],
          options: {
            url: `api/vendor-action-log/get-statuses`,
            multiple: true,
            liveOptions: true,
            checkbox: true,
            forceAvatar: false
          }
        },
        size: 3
      },
      {
        name: "VendorTrackerDetails",
        description: "Details",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },
      {
        name: "VendorTrackerAssignedVendor",
        description: "Assigned Vendor",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      }
      /*{
        name: "Period",
        description: "Period",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 0,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      },
      {
        name: "Level",
        description: "Level",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: levels,
          defaultValue: ""
        },
        size: 2
      },
      {
        name: "Message",
        description: "Message",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },
      {
        name: "IsUser",
        description: "User",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/users/dropdown`,
            multiple: false,
            liveOptions: true,
            checkbox: false,
            forceAvatar: true
          }
        },
        size: 3
      },
      {
        name: "SourceContextIsAnyOf",
        description: "Context",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "autocomplete",
          defaultValue: [
            { id: 1, text: "Account Sync" },
            { id: 2, text: "Contact Sync" },
            {
              id: 3,
              text: "Contact Relations Sync"
            }
          ],
          options: {
            url: `api/logs/source-contexts/dropdown`,
            multiple: true,
            liveOptions: true,
            checkbox: true
          }
        },
        size: 3
      },

      {
        name: "Guid",
        description: "Guid",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Guid"
        },
        size: 4
      },
      {
        name: "Properties",
        description: "Properties",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Properties"
        },
        size: 4
      }*/
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Vendor Action Logs"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/vendor-action-log/get-logs"}
            label={"log items"}
            renderItem={(item, index) => <Entry key={index} entry={item} />}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 400, 800]}
            rowsPerPage={50}
            refresh={state.refresh}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
