import dayjs from "dayjs/esm";
import utc from "dayjs/esm/plugin/utc";
import timezone from "dayjs/esm/plugin/timezone";
import localizedFormat from "dayjs/esm/plugin/localizedFormat";
import advancedFormat from "dayjs/esm/plugin/advancedFormat";
import customParseFormat from "dayjs/esm/plugin/customParseFormat";
import isBetween from "dayjs/esm/plugin/isBetween";
import isYesterday from "dayjs/esm/plugin/isYesterday";
import isToday from "dayjs/esm/plugin/isToday";
import isTomorrow from "dayjs/esm/plugin/isTomorrow";
import relativeTime from "dayjs/esm/plugin/relativeTime";
import "dayjs/esm/locale/en";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);

dayjs.locale("en");
export {
  dayjs,
  utc,
  timezone,
  localizedFormat,
  advancedFormat,
  customParseFormat,
  isBetween,
  isYesterday,
  isToday,
  isTomorrow,
  relativeTime
};
