import React, { useContext } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EntryUser from "./EntryUser";
import { HomeAppContext } from "../../../Home";
import Slide from "@mui/material/Slide";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import MyDataGrid from "../../../General/Pagination/MyDataGrid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const statusValues = [
  { id: "true", text: "Active" },
  { id: "false", text: "Inactive" }
];

export default function AddUsersDialog({
                                         open,
                                         setOpen,
                                         users = [],
                                         onAddUsers,
                                         filterLinguistsOut
                                       }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [selectedItems, _setSelectedItems] = React.useState([]);
  const [countItems, setCountItems] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [internalLoading, setInternalLoading] = React.useState(false);
  const url = "/api/system/ad-hoc-notifications/users";

  const setSelectedItems = (items) =>
    _setSelectedItems(items.reduce((acc, x) => [...acc, x.id], []));

  const tableOptions = {
    columns: [
      {
        name: "FullName",
        description: "Name",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 4
      },
      {
        name: "RoleId",
        description: "Role",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "ActivityTypeIsAnyOf",
        description: "Activity Types",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "LanguageAnyOf",
        description: "Languages",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      }
    ],
    defaultSort: [{ name: "FullName", order: "" }],
    additionalFilters: [
      {
        name: "NameOrEmailLike",
        description: "Name",
        filter: {
          enabled: true,
          primary: false,
          name: "NameOrEmailLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 4
      },
      {
        name: "RoleId",
        description: "Role",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/roles/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false,
            optionsDisabled: filterLinguistsOut ? [{ id: 3 }] : []
          }
        },
        size: 2
      },
      {
        name: "ActivityTypeIsAnyOf",
        description: "Activity Types",
        filter: {
          enabled: true,
          type: "autocomplete",
          comparison: "==",
          options: {
            url: "/api/activity-types/dropdown",
            multiple: true,
            liveOptions: false,
            checkbox: true
          },
          defaultIndex: 0
        },
        size: 4
      },
      {
        name: "LanguageAnyOf",
        description: "Languages",
        filter: {
          enabled: true,
          type: "autocomplete",
          comparison: "==",
          options: {
            url: "/api/languages/dropdown",
            multiple: true,
            liveOptions: false,
            checkbox: true
          },
          defaultIndex: 0
        },
        size: 4
      },
      {
        name: "Active",
        description: "Active Status",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "select",
          value: statusValues,
          defaultValue: "true"
        },
        size: 2
      }
    ],
    preFilters: filterLinguistsOut
      ? {
        RoleId: {
          comparison: "!=",
          value: 3
        }
      }
      : {}
  };

  const handleClose = () => {
    setOpen(false);
    setInternalLoading(false);
  };

  const handleCloseAddAll = () => {
    const getItems = async () => {
      setInternalLoading(true);
      const params = {
        page: 1,
        pageSize: 1000,
        filters: filters
      };
      try {
        const response = await api.get(url, {
          params: params
        });
        if (response.data.count !== 0) {
          onAddUsers(response.data.items.map((i) => i.id));
        }
        setInternalLoading(false);
        setOpen(false);
      } catch (error) {
        setInternalLoading(false);
        errorManagement.formErrors(error, homeDispatch);
      }
    };
    getItems();
  };

  const handleCloseAdd = () => {
    setOpen(false);
    onAddUsers(selectedItems);
    setInternalLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-title"
    >
      <DialogTitle id="alert-dialog-edit-title">{"Users"}</DialogTitle>
      <DialogContent>
        <MyDataGrid
          options={tableOptions}
          multiSelect={true}
          filterOutIds={users.map((x) => x.id)}
          onSelectedItemsChange={setSelectedItems}
          onItemsCountChange={setCountItems}
          onFiltersUpdate={setFilters}
          onLoading={setLoading}
          url={url}
          label={"Users"}
          renderItem={(item, selectionProperties, selectionCheckbox) => <EntryUser key={item.id} entry={item}
                                                                                   selectionProperties={selectionProperties}
                                                                                   selectionCheckbox={selectionCheckbox} />}
          loading={internalLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleCloseAddAll}
          disabled={countItems === 0 || loading || internalLoading}
        >
          Add All Users
        </Button>
        <Button
          onClick={handleCloseAdd}
          disabled={selectedItems.length === 0 || loading || internalLoading}
        >
          Add Selected Users
        </Button>
      </DialogActions>
    </Dialog>
  );
}
