import { Link, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import Button from "@mui/material/Button";
import { DateTimeEntry } from "./DateTimeEntry";
import { HomeAppContext } from "../Home";
import LinearProgressWithLabel from "../General/LinearProgressWithLabel";
import { dayjs } from "../../Services/dateUtil";
import makeStyles from "@mui/styles/makeStyles";
import { orange } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  stickyCell1: {
    position: "sticky",
    left: "0px",
    backgroundColor: theme.palette.background.default,
    zIndex: 2
  },
  stickyCell2: {
    position: "sticky",
    left: "70px",
    backgroundColor: theme.palette.background.default,
    zIndex: 2
  },
  rowOrange: {
    backgroundColor: orange[400],
    color: theme.palette.getContrastText(orange[400]),
    "& .MuiInputBase-input": {
      color: theme.palette.getContrastText(orange[400])
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.getContrastText(orange[400])
    },
    "& .MuiInput-underline::before": {
      borderBottomColor: theme.palette.getContrastText(orange[400])
    },
    fontSize: 11,
    cursor: "pointer"
  },
  stickyCell3: {
    position: "sticky",
    left: "180px",
    backgroundColor: theme.palette.background.default,
    zIndex: 2
  }
}));
export const weightedWords = (entry) => {
  const sum = entry.catGrid.tmSavings.reduce((acc, x) => {
    const rate = new BigNumber(x.rate);
    return acc.plus(rate.times(x.quantity));
  }, BigNumber(0));
  return sum.dividedBy(100).toPrecision(5);
};

const Entry = ({
  entry,
  selectedProject,
  onSelectProject,
  managers,
  statuses,
  showDialog,
  acceptJob,
  onMultiEdit,
  selectionCheckbox
}) => {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [loading, setLoading] = useState(false);
  const [editNoteDialogOpen, setEditNoteDialogOpen] = useState(false);

  const [localEntry, setLocalEntry] = useState(null);

  useEffect(() => {
    setLocalEntry({
      ...entry,
      id: entry.id,
      name: entry.name,
      fileName: entry.fileName,
      division: entry.division,
      dateCreated: entry.dateCreated,
      totalWC: entry.wordCount,
      totalWCWeighted: entry.weightedWordCount,
      dueDate: entry.dueDate,
      status: entry.status,
      uid: entry.uid,
      sourceLanguage: entry.sourceLanguage,
      workflowStep: entry.workflowStep,
      vendorDeadline: entry.vendorDeadline,
      atmsJobId: entry.atmsJobId,

      percentCompleted: entry.percentCompleted,
      statusForVendors: entry.statusForVendors,
      atmsUserName: entry.atmsUserName,
      selected: entry.selected,
      reviewDeadline: entry.reviewDeadline,
      batchId: entry.batchId,

      _changed: 0
    });
  }, [entry]);

  function openUrl(localEntry) {
    var url = process.env.REACT_APP_ATMS_PROJECT_URL + localEntry.uid;
    window.open(url, "_blank");
  }

  return (
    localEntry !== null && (
      <React.Fragment>
        <TableRow>
          <TableCell>{selectionCheckbox}</TableCell>
          <TableCell align={"left"}>{localEntry.batchId}</TableCell>
          <TableCell>
            {/*<Link*/}
            {/*  href={process.env.REACT_APP_ATMS_PROJECT_URL + localEntry.uid}*/}
            {/*  target={"_blank"}*/}
            {/*  variant="body2"*/}
            {/*>*/}
            {/*  {localEntry.name}*/}
            {/*</Link>*/}
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    {localEntry.name}
                  </Typography>
                </React.Fragment>
              }
            >
              <Button onClick={() => openUrl(localEntry)}>Open</Button>
            </Tooltip>
          </TableCell>
          <TableCell align={"left"}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    {localEntry.fileName}
                  </Typography>
                </React.Fragment>
              }
            >
              <Link
                href={`${process.env.REACT_APP_ATMS_PROJECT_URL_TRANSLATION.replace(
                  "[id]",
                  localEntry.atmsJobId
                )}`}
                target={"_blank"}
                variant="body2"
              >
                {localEntry.fileName != null &&
                  localEntry.fileName.substring(0, 20)}
              </Link>
            </Tooltip>
          </TableCell>
          <TableCell align={"left"}>{localEntry.division}</TableCell>
          <TableCell align={"left"}>{localEntry.sourceLanguage}</TableCell>
          <TableCell align={"left"}>{localEntry.targetLanguage}</TableCell>
          <TableCell align={"left"}>{localEntry.totalWC}</TableCell>
          <TableCell align={"left"}>
            <Button onClick={() => showDialog(localEntry)}>Show</Button>
          </TableCell>
          <TableCell align={"left"}>
            <LinearProgressWithLabel
              variant={"determinate"}
              color="primary"
              value={localEntry.percentCompleted}
            />
          </TableCell>
          <TableCell align={"left"}>{localEntry.workflowStep}</TableCell>
          <TableCell align={"left"}>
            {localEntry.statusForVendors}
            {(localEntry.statusForVendors === "NEW" ||
              localEntry.statusForVendors === "EMAILED") && (
              <Button onClick={() => acceptJob(localEntry)}>Accept</Button>
            )}
          </TableCell>
          <TableCell align={"left"}>
            <DateTimeEntry dateTime={localEntry.vendorDeadline} />
          </TableCell>
          <TableCell align={"left"}>
            <DateTimeEntry dateTime={localEntry.reviewDeadline} />
          </TableCell>
          <TableCell align={"left"}>{localEntry.atmsUserName}</TableCell>
        </TableRow>
      </React.Fragment>
    )
  );
};

export default Entry;
