import { Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {green, lightBlue} from "@mui/material/colors";

import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  HeaderIcon: { fontSize: 18 },
  ChipSmall: {
    fontSize: 12,
    paddingTop: 1,
    height: "auto",
  },
  pendingColor: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
  },
  sentColor: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
}));

const ExecutedStatus = ({ value }) => {
  const classes = useStyles();

  return value ? (
    <Chip
      color="primary"
      size="small"
      label="SENT"
      className={clsx(classes.ChipSmall, classes.sentColor)}
    />
  ) : (
    <Chip
      color="secondary"
      size="small"
      label="PENDING"
      className={clsx(classes.ChipSmall, classes.pendingColor)}
    />
  );
};

export default ExecutedStatus;
