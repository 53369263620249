import _ from "lodash";

export const errorManagement = {
  formErrors(error, homeDispatch, setErrors = null, messageOverride = {},setResponse = null) {
    if (error !== undefined) {
      if (error.response !== undefined) {
        if (_.isString(error.response.data)) {
          if (error.response.status === 403) {
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: "Unauthorized access", type: "error" },
            });
          } else {
            homeDispatch({
              type: "NOTIFICATION",
              data: {
                message:
                  messageOverride[error.response.status] ?? error.response.data,
                type: "warning",
              },
            });
          }
          if (setResponse !== null) setResponse(error.response.data)

        } else if (
          _.isObject(error.response.data) &&
          error.response.data.hasOwnProperty("errors")
        ) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { close: true },
          });
          if (setErrors != null) setErrors(error.response.data.errors);
        } else if (error?.response?.status === 404) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Not Found", type: "error" },
          });
        } else if (error?.response?.status === 400) {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: messageOverride[400] ?? "Bad request",
              type: "error",
            },
          });
        } else {
          console.log(error);
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Unrecognized error", type: "error" },
          });
        }
      } else {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Something was not right", type: "error" },
        });
      }
    } else {
      console.log("error is not defined");
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Something was not right", type: "error" },
      });
    }
  },
};
