import { Chip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {green, lightBlue, red, yellow} from "@mui/material/colors";

import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  ChipSmall: {
    fontSize: 12,
    paddingTop: 1,
    height: "auto",
  },
  pendingColor: {
    color: theme.palette.getContrastText(lightBlue[600]),
    backgroundColor: lightBlue[600],
  },
  retryColor: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
  },
  failedColor: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
  },
  sentColor: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
}));

const EmailLogStatus = ({ value, retries }) => {
  const classes = useStyles();

  return value
    ? {
        Pending: (
          <Chip
            size="small"
            label="PENDING"
            className={clsx(classes.ChipSmall, classes.pendingColor)}
          />
        ),
        Retry: (
          <Chip
            size="small"
            label={`RETRY (${retries})`}
            className={clsx(classes.ChipSmall, classes.retryColor)}
          />
        ),
        Failed: (
          <Chip
            size="small"
            label="FAILED"
            className={clsx(classes.ChipSmall, classes.failedColor)}
          />
        ),
        Sent: (
          <Chip
            size="small"
            label="SENT"
            className={clsx(classes.ChipSmall, classes.sentColor)}
          />
        ),
        default: (
          <Chip size="small" label={value.name} className={classes.ChipSmall} />
        ),
      }[value.name]
    : null;
};

export default EmailLogStatus;
