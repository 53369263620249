import { TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CustomAvatar from "../../../General/CustomAvatar";
import Grid from "@mui/material/Grid";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  tooltipWidth: {
    maxWidth: 400
  },
  simpleIcon: { verticalAlign: "sub", fontSize: 18 },
  currency: { textAlign: "right" },
  infoIcon: {
    color: theme.palette.secondary.light,
    marginRight: 2,
    verticalAlign: "middle",
    fontSize: "inherit"
  },
  allSmallIcon: {
    verticalAlign: "middle",
    fontSize: "inherit",
    marginRight: 4
  },
  inactive: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800]
  },
  ChipSmall: {
    fontSize: 12,
    marginLeft: 5,
    paddingTop: 1,
    height: "auto"
  }
}));

const EntryUser = ({
                     entry,
                     selectionProperties,
                     selectionCheckbox
                   }) => {
  const classes = useStyles();

  return (
    <TableRow className={clsx({ [classes.inactive]: !entry.active })} sx={selectionProperties}>
      <TableCell>
        {selectionCheckbox}
      </TableCell>
      <TableCell>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CustomAvatar
              text={entry.fullName}
              picture={entry.picture}
              size={"normal"}
            />
          </Grid>
          <Grid
            item
            style={{
              flex: 1,
              minWidth: 0,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Grid item>
              <Typography variant="body2" display="inline">
                {entry.fullName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        {entry.role && entry.role.description}
      </TableCell>
    </TableRow>
  );
};

export default EntryUser;
