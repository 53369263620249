import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useCallback, useEffect, useState } from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Grid from "@mui/material/Grid";
import stylesCalendarStatus from "./Styles/stylesCalendarStatus";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1
});


const defaultPeriodFilters = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Current week",
    from: dayjs().startOf("isoWeek").startOf("day"),
    to: dayjs().endOf("isoWeek").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  },
  {
    label: "Last 2 months",
    from: dayjs().subtract(2, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];

const nullPeriod = {
  label: "All",
  from: null,
  to: null
};

const CalendarRangeContainer = styled("div")(({ theme }) => ({
  width: "650px"
}));

const MyToolbar = ({ toolbarTitle, parsedValue }) => {
  return (
    <Box sx={{ my: 2, mx: 4 }}>
      <Typography variant="overline" color="primary">
        {toolbarTitle}
      </Typography>
      <Typography variant="h5">
        {parsedValue?.format("MMM DD, YYYY")}
      </Typography>
    </Box>
  );
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween"
    && prop !== "isFirstDay"
    && prop !== "isLastDay"
    && prop !== "isWeekEnd"
})(({ theme, dayIsBetween, isFirstDay, isLastDay, isWeekEnd }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "0%",
    borderBottomRightRadius: "0%"
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  }),
  ...(isWeekEnd && {
    color: "red"
  })
}));

const initialState = {
  period: defaultPeriodFilters[0],
  from: defaultPeriodFilters[0].from,
  to: defaultPeriodFilters[0].to,
  internalFrom: defaultPeriodFilters[0].from,
  internalTo: defaultPeriodFilters[0].to,
  customPeriod: []
};

export default function CustomDateRange({
                                          defaultValue = 0,
                                          periodFilters = defaultPeriodFilters,
                                          onSetValue = null,
                                          views = ["year", "month", "day"],
                                          format = "ll",
                                          enableButton = true,
                                          label = "Period",
                                          size = "small",
                                          disabled = false,
                                          addNullPeriod = false
                                        }) {
  const calendarClasses = stylesCalendarStatus();

  const [state, setState] = useState(initialState);
  const callbackOnSetValue = useCallback(onSetValue, []);
  const periods = addNullPeriod
    ? [...state.customPeriod, ...periodFilters, nullPeriod]
    : [...state.customPeriod, ...periodFilters];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const defaultPeriods = addNullPeriod
      ? [...periodFilters, nullPeriod]
      : periodFilters;
    const index = defaultValue !== -1 ? defaultValue : defaultPeriods.length - 1;
    setState((prevState) => ({
      ...prevState,
      period: defaultPeriods[index],
      from: defaultPeriods[index].from,
      to: defaultPeriods[index].to,
      internalFrom: defaultPeriods[index].from,
      internalTo: defaultPeriods[index].to
    }));
    if (callbackOnSetValue !== null)
      callbackOnSetValue(
        defaultPeriods[index].from === null && defaultPeriods[index].to === null
          ? null
          : {
            from: defaultPeriods[index].from.format("YYYY-MM-DD"),
            to: defaultPeriods[index].to.format("YYYY-MM-DD")
          }
      );
  }, [addNullPeriod, callbackOnSetValue, defaultValue, periodFilters]);

  const handleOpen = (event) => {
    setState({ ...state, internalFrom: state.from, internalTo: state.to });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAccept = () => {
    const newPeriod = {
      label: "Custom",
      from: state.internalFrom,
      to: state.internalTo
    };
    setState({
      ...state,
      from: newPeriod.from,
      to: newPeriod.to,
      customPeriod: [newPeriod],
      period: newPeriod
    });
    onRangeChange(newPeriod.from, newPeriod.to);
    setAnchorEl(null);
  };

  const onRangeChange = (from, to) => {
    if (callbackOnSetValue !== null)
      callbackOnSetValue(
        from === null && to === null
          ? null
          : {
            from: from.format("YYYY-MM-DD"),
            to: to.format("YYYY-MM-DD")
          }
      );
  };

  const handleOnPeriod = (event) => {
    const period = periods.find((item) => item.label === event.target.value);
    setState({ ...state, from: period.from, to: period.to, period: period });
    onRangeChange(period.from, period.to);
  };

  const handleFromDateChange = (value) => {
    setState({ ...state, internalFrom: value });
  };
  const handleToDateChange = (value) => {
    setState({ ...state, internalTo: value });
  };

  const renderWrappedWeekDay = (
    date,
    selectedDate,
    pickersDayProps
  ) => {
    const isFirstDay = date.isSame(state.internalFrom, "day");
    const isLastDay = date.isSame(state.internalTo, "day");
    const isWeekEnd = date.day() === 6 || date.day() === 0;
    const dayIsBetween = date.isBetween(state.internalFrom, state.internalTo, null, "[]");
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isWeekEnd={isWeekEnd}

      />
    );
  };

  return (
    <Grid container alignItems="flex-end" spacing={1}>
      <Grid item sx={{ flex: 1 }}>
        <FormControl fullWidth>
          <InputLabel sx={size === "small" ? { fontSize: 12 } : {}}>
            {label}
          </InputLabel>
          <Select
            labelId="period_label"
            id="period-menu"
            sx={size === "small" ? { fontSize: 12 } : {}}
            value={state.period.label}
            onChange={handleOnPeriod}
            disabled={disabled}
            renderValue={(value) => (
              <Typography sx={{ fontSize: "0.8em" }}>{value}</Typography>
            )}
          >
            {periods.map((period, index) => (
              <MenuItem key={index} value={period.label}>
                {period.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">
              {state.from === null ? "" : dayjs(state.from).format(format)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {state.to === null ? "" : dayjs(state.to).format(format)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {enableButton && (
        <React.Fragment>
          <Grid item sx={{ alignSelf: "center" }}>
            <IconButton
              onClick={handleOpen}
              size="small"
              disabled={disabled || (state.from == null && state.to == null)}
              color="secondary"
            >
              <DateRangeIcon />
            </IconButton>
          </Grid>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <CalendarRangeContainer>
              <Grid container justifyContent="flex-end">
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      views={views}
                      maxDate={state.internalTo}
                      renderDay={renderWrappedWeekDay}
                      autoOk
                      value={state.internalFrom}
                      onChange={handleFromDateChange}
                      inputFormat={format}
                      renderInput={props => <TextField {...props} label="From" />}
                      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase()
                        + day.slice(1).toLowerCase()}
                      showDaysOutsideCurrentMonth={true}
                      showToolbar={true}
                      toolbarTitle={"From date"}
                      ToolbarComponent={(params) => <MyToolbar {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      views={views}
                      inputFormat={format}
                      renderInput={props => <TextField {...props} label="To" />}
                      minDate={state.internalFrom}
                      renderDay={renderWrappedWeekDay}
                      autoOk
                      value={state.internalTo}
                      onChange={handleToDateChange}
                      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase()
                        + day.slice(1).toLowerCase()}
                      showDaysOutsideCurrentMonth={true}
                      showToolbar={true}
                      ToolbarComponent={(params) => <MyToolbar {...params} />}
                      toolbarTitle={"To date"}

                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleCloseAccept} color="primary" autoFocus>
                    Apply new ranges
                  </Button>
                </Grid>
              </Grid>
            </CalendarRangeContainer>
          </Popover>
        </React.Fragment>
      )}
    </Grid>
  );
}
