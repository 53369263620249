import { Hidden, styled, useMediaQuery } from "@mui/material";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from "@microsoft/signalr";
import React, { useContext, useEffect, useReducer } from "react";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from "@mui/material/styles";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Notification from "./Notification";
import RenderRouter from "./RenderRouter";
import SidePanel from "./General/SidePanel";
import Sidebar from "./Sidebar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserMenu from "./User/UserMenu";
import api from "../Services/api";
import uniqid from "uniqid";
import { useLocation } from "react-router-dom";
import { userStore } from "../Services/store";
import Box from "@mui/material/Box";

const drawerWidthOpen = "240px";
const drawerWidthClosed = "65px";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    secondary: {
      main: "#2F4050",
      light: "#4d6882"
    },
    primary: {
      main: "#0da67a",
      light: "#4ca48b"
    }
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1500px"
        }
      },
      defaultProps: {
        maxWidth: "1500px"
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&+.MuiDialogContent-root": {
            paddingTop: "20px !important"
          }
        }
      },
      defaultProps: {
        backgroundColor: "#0da67a",
        color: "white"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: "1.43",
          letterSpacing: "0.01071em"
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#333"
    },
    secondary: {
      main: "#85AFD7",
      light: "#a5bcd1"
    },
    primary: {
      main: "#0da67a",
      light: "#4ca48b"
    }
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1500px"
        }
      },
      defaultProps: {
        maxWidth: "1500px"
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&+.MuiDialogContent-root": {
            paddingTop: "20px !important"
          }
        }
      },
      defaultProps: {
        backgroundColor: "#0da67a",
        color: "white"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: "1.43",
          letterSpacing: "0.01071em"
        }
      }
    }
  }
});

const HomeAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !["userProfile", "menuOpen"].includes(prop)
})(({ theme, userProfile, menuOpen }) => ({
  backgroundColor: "#0da67a",
  color: "white",
  ...(userProfile !== null
    ? menuOpen
      ? {
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidthOpen})`,
            marginLeft: drawerWidthOpen
          }
        }
      : {
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidthClosed})`,
            marginLeft: drawerWidthClosed
          }
        }
    : {
        [theme.breakpoints.up("sm")]: {
          marginLeft: drawerWidthOpen
        }
      })
}));

const HomeNav = styled("nav", {
  shouldForwardProp: (prop) => !["menuOpen"].includes(prop)
})(({ theme, menuOpen }) => ({
  [theme.breakpoints.up("sm")]: {
    width: menuOpen ? drawerWidthOpen : drawerWidthClosed,
    flexShrink: 0
  }
}));

const HomeSidebarMenuSpacer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar
}));

export const HomeAppContext = React.createContext();

const initialState = {
  connection: null,
  currentLocation: "",
  notification: "",
  userProfile: userStore.user_profile,
  sidePanelInfo: []
};

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_CURRENT_LOCATION":
      return { ...state, currentLocation: "Vendor Tracker - " + action.data };
    case "NOTIFICATION":
      return { ...state, notification: { ...action.data, key: uniqid() } };
    case "NOTIFICATION_REPORT":
      if (state.connection.state === HubConnectionState.Disconnected)
        state.connection.start();
      return { ...state, notification: { ...action.data, key: uniqid() } };
    case "ADD_TO_SIDE_PANEL_INFO":
      const previousTask = state.sidePanelInfo.find(
        (task) => task.guid === action.data.guid
      );
      if (previousTask !== undefined) {
        return {
          ...state,
          sidePanelInfo: state.sidePanelInfo.map((task) =>
            task.guid === action.data.guid
              ? { ...task, messages: task.messages.concat([action.data]) }
              : task
          )
        };
      } else {
        return {
          ...state,
          sidePanelInfo: state.sidePanelInfo.concat([
            {
              guid: action.data.guid,
              variation: action.data.variation,
              messages: [action.data]
            }
          ])
        };
      }
    case "UPDATE_USER_PROFILE":
      if (
        state.userProfile === null ||
        state.userProfile.userId !== action.data.userId
      ) {
        return { ...state, userProfile: { ...action.data, key: uniqid() } };
      } else {
        return state;
      }
    case "UPDATE_USER_PROFILE_VALUES":
      userStore.user_profile = action.data;

      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.data, key: uniqid() }
      };

    case "CLEAR_USER_PROFILE":
      if (state.connection) {
        state.connection.stop();
      }
      return { ...state, sidePanelInfo: [], userProfile: null };
    case "SET_CONNECTION":
      return { ...state, connection: action.data };
    default:
      return initialState;
  }
}

const Main = (props) => {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const { container } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(userStore.sidebarMenuOpen);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleMenuToggle = () => {
    const value = !menuOpen;
    setMenuOpen(value);
    userStore.sidebarMenuOpen = value;
  };

  useEffect(() => {
    if (userStore.user_profile !== null) {
      homeDispatch({
        type: "UPDATE_USER_PROFILE",
        data: userStore.user_profile
      });
    } else {
      homeDispatch({
        type: "CLEAR_USER_PROFILE",
        data: null
      });
    }
  }, []);

  useEffect(() => {
    if (homeState.userProfile !== null) {
      if (homeState.connection) {
        homeState.connection.stop();
      }
      const newConnection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(process.env.REACT_APP_BACKEND_URL + "/notificationhub", {
          accessTokenFactory: async () => {
            let accessToken = null;
            await api
              .get("/api/login/authorized")
              .then(async (response) => {
                accessToken = userStore.token;
              })
              .catch((error) => {
                console.log(error);
              });

            return accessToken;
          }
        })
        .withAutomaticReconnect()
        .build();
      homeDispatch({
        type: "SET_CONNECTION",
        data: newConnection
      });
    } else {
      if (homeState.connection) {
        homeState.connection.stop();
      }
      const newConnection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(process.env.REACT_APP_BACKEND_URL + "/notificationhub")
        .withAutomaticReconnect()
        .build();
      homeDispatch({
        type: "SET_CONNECTION",
        data: newConnection
      });
    }
  }, [homeState.userProfile]);

  useEffect(() => {
    if (
      homeState.connection &&
      homeState.connection.state === HubConnectionState.Disconnected
    ) {
      homeState.connection
        .start()
        .then((result) => {
          homeState.connection.on("ReceiveMessage", (notification) => {
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: notification.message, type: notification.type }
            });
          });
          homeState.connection.on(
            "ReceiveTaskProgressMessage",
            (notification) => {
              homeDispatch({
                type: "ADD_TO_SIDE_PANEL_INFO",
                data: notification
              });
            }
          );
          homeState.connection.on("ReceiveDownloadLink", (notification) => {
            window.location = notification.link;
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [homeState.connection]);
  const getMaxWidth = () => {
    switch (location.pathname) {
      case "/tracker":
        return false;
      default:
        return "lg";
    }
  };
  return (
    <React.Fragment>
      <HomeAppBar
        position="fixed"
        userProfile={homeState.userProfile}
        menuOpen={menuOpen}
      >
        <Toolbar sx={{ paddingRight: "24px" }}>
          {homeState.userProfile !== null ? (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={(theme) => ({
                  marginRight: theme.spacing(2),
                  [theme.breakpoints.up("sm")]: {
                    display: "none"
                  }
                })}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMenuToggle}
                sx={(theme) => ({
                  marginRight: theme.spacing(2),
                  [theme.breakpoints.down("sm")]: {
                    display: "none"
                  }
                })}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </React.Fragment>
          ) : null}
          <Box
            component={"img"}
            src="/favicon-196x196-white.png"
            alt="logo"
            sx={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              marginBottom: "5px"
            }}
          />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {homeState.currentLocation}
          </Typography>
          {homeState.userProfile !== null ? (
            <UserMenu userProfile={homeState.userProfile} />
          ) : null}
        </Toolbar>
      </HomeAppBar>
      {homeState.userProfile !== null ? (
        <HomeNav menuOpen={menuOpen} aria-label="sidebar-menu">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true
              }}
              sx={{
                width: drawerWidthOpen
              }}
            >
              <HomeSidebarMenuSpacer />
              <Divider />
              <Sidebar
                userProfile={homeState.userProfile}
                handleDrawerClose={handleDrawerClose}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              PaperProps={{
                sx: { width: menuOpen ? drawerWidthOpen : drawerWidthClosed }
              }}
            >
              <HomeSidebarMenuSpacer />
              <Divider />
              <Sidebar
                userProfile={homeState.userProfile}
                handleDrawerClose={handleDrawerClose}
                menuOpen={menuOpen}
              />
            </Drawer>
          </Hidden>
        </HomeNav>
      ) : null}
      <Box
        component={"main"}
        id="appMainContent"
        sx={(theme) => ({
          background: theme.palette.background.default,
          flexGrow: 1,
          height: "100vh",
          overflow: "auto"
        })}
      >
        <Container
          maxWidth={getMaxWidth()}
          sx={(theme) => ({
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(4),
            height: "100%"
          })}
        >
          <RenderRouter userProfile={homeState.userProfile} />
        </Container>
        {homeState.notification ? (
          <Notification
            key={homeState.notification.key}
            value={homeState.notification}
          />
        ) : null}
        <SidePanel sidePanelInfo={homeState.sidePanelInfo} />
      </Box>
    </React.Fragment>
  );
};

function Home(props) {
  const [homeState, homeDispatch] = useReducer(reducer, initialState);

  const autoDark = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = homeState.userProfile
    ? homeState.userProfile.theme === "Auto"
      ? autoDark
      : homeState.userProfile.theme === "Dark"
    : autoDark;

  return (
    <HomeAppContext.Provider value={{ homeState, homeDispatch }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
          <CssBaseline />
          <Main />
        </ThemeProvider>
      </StyledEngineProvider>
    </HomeAppContext.Provider>
  );
}

export default Home;
