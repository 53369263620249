import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React, { useContext } from "react";

import { HomeAppContext } from "../../Home";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import WelcomeMessageTest from "./WelcomeMessageTest";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const initialState = {
  tabIndex: 0,
  tabTransitionEnded: true
};
export default function List() {
  const theme = useTheme();

  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = React.useState(initialState);

  const handleChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex: newValue,
      tabTransitionEnded: false
    }));
  };

  const handleChangeIndex = (index) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex: index,
      tabTransitionEnded: false
    }));
  };

  const onTransitionEnd = () => {
    setState((prevState) => ({
      ...prevState,
      tabTransitionEnded: true
    }));
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Tests - Notifications"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Tabs
        value={state.tabIndex}
        indicatorColor="secondary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Welcome Message" />
      </Tabs>
      <SwipeableViews
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s"
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={state.tabIndex}
        onChangeIndex={handleChangeIndex}
        onTransitionEnd={onTransitionEnd}
      >
        <TabPanel value={state.tabIndex} index={0} dir={theme.direction}>
          <WelcomeMessageTest
            tabTransitionEnded={
              state.tabTransitionEnded && state.tabIndex === 0
            }
          />
        </TabPanel>
      </SwipeableViews>
    </React.Fragment>
  );
}
