import Form, { UserFormMode } from "./Form";
import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../../Home";
import Slide from "@mui/material/Slide";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  fullName: "",
  email: "",
  role: { id: 3 },
  picture: "",
  pictureChanged: false,
  active: true,
  sendWelcomeMessage: true,
  authType: { id: 2, name: "Internal" },
  atmsUser: { id: 0, text: "" },
  atmsUserAdditional: [],
  xtrfUser: { id: 0, text: "" }
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  React.useEffect(() => {
    if (open) {
      setSubmitted(false);
      setErrors([]);
      setInternalEntry(initialEntry);
      setLoading(false);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Creating User", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newUser = {
      fullName: internalEntry.fullName,
      email: internalEntry.email,
      roleId: internalEntry.role !== "" ? internalEntry.role.id : 0,
      picture: internalEntry.pictureChanged ? internalEntry.picture : null,
      active: internalEntry.active,
      sendWelcomeMessage: internalEntry.sendWelcomeMessage,
      atmsUser: internalEntry.atmsUser,
      atmsUserAdditional: internalEntry.atmsUserAdditional,
      xtrfUser: internalEntry.xtrfUser
    };

    api
      .post(`/api/users/`, newUser)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "User created", type: "success" }
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">{"New User"}</DialogTitle>
      <DialogContent>
        <Form
          mode={UserFormMode.NEW}
          entity={internalEntry}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
