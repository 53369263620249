import React, { useContext } from "react";

import Edit from "./Edit";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import MyDataGrid from "../../../General/Pagination/MyDataGrid";

export default function List(props) {
  const { homeDispatch } = useContext(HomeAppContext);

  const [openEdit, setOpenEdit] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const tableOptions = {
    columns: [
      {
        name: "JobName",
        description: "Name",
        sortable: true,
        filter: {
          name: "JobNameOrDescriptionLike",
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 3
      },
      {
        name: "ExecuteDayMode",
        description: "Mode",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Time",
        description: "Executes at",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedBy",
        description: "Created by",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "UpdatedBy",
        description: "Updated by",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 2
      }, ,
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "JobName", order: "" }],
    additionalFilters: []
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "System - Notifications"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/system/notifications"}
            refresh={refresh}
            label={"Notifications"}
            renderItem={(item, index) => (
              <Entry key={item.id} entry={item} onEdit={handleClickEdit} />
            )}
          />
        </Grid>
      </Grid>
      <Edit
        open={openEdit}
        setOpen={setOpenEdit}
        entry={currentEntry}
        onEdit={refreshItems}
      />
    </React.Fragment>
  );
}
