import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomAutocomplete from "../../../General/CustomAutocomplete";
import Grid from "@mui/material/Grid";
import React from "react";
import { TextField, Typography } from "@mui/material";
import _ from "lodash";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function Form({
  entry = null,
  errors = [],
  setEntry,
  loading,
  submitted,
  setSaveDisabled
}) {
  const classes = useStyles();

  const onValueChange = (value, property) =>
    setEntry({ ...entry, [property]: value });

  return entry === null ? null : (
    <Grid container className={classes.paper} spacing={2}>
      <Grid item sm={12}>
        <Typography variant="body2">{entry.jobName}</Typography>
        <Typography variant="caption">{entry.description}</Typography>
      </Grid>

      <Grid item sm={12}>
        <CustomAutocomplete
          value={entry.executeDayMode}
          setValue={(value) => onValueChange(value, "executeDayMode")}
          related={null}
          onLoading={setSaveDisabled}
          url={"/api/system/notifications/execute-day-modes/dropdown"}
          label={"Mode"}
          placeholder={"Select a mode"}
          noOptionsText={"Mode found"}
          loadingText={"Searching"}
          margin={"normal"}
          liveOptions={false}
          error={_.isArray(errors.executeDayMode)}
          showHelperText={true}
          helperText={
            entry.executeDateModeApplies
              ? _.isArray(errors.executeDayMode) && errors.executeDayMode.join()
              : "Not applicable"
          }
          disableClearable={true}
          disabled={!entry.executeDateModeApplies}
        />
      </Grid>

      <Grid item sm={12}>
        <CustomAutocomplete
          value={entry.day}
          setValue={(value) => onValueChange(value, "day")}
          related={null}
          onLoading={setSaveDisabled}
          url={"/api/system/notifications/day/dropdown"}
          label={"Day"}
          placeholder={"Select a day"}
          noOptionsText={"Day found"}
          loadingText={"Searching"}
          margin={"normal"}
          liveOptions={false}
          error={_.isArray(errors.day)}
          showHelperText={true}
          helperText={
            entry.allowDayChange
              ? _.isArray(errors.day) && errors.day.join()
              : "Not applicable"
          }
          disableClearable={true}
          disabled={!entry.allowDayChange}
        />
      </Grid>

      <Grid item sm={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            ampm={false}
            margin="normal"
            variant="inline"
            autoOk
            value={entry.time}
            renderInput={(props) => (
              <TextField
                {...props}
                label="Execution time"
                error={_.isArray(errors.time)}
                helperText={_.isArray(errors.time) && errors.time.join()}
              />
            )}
            onChange={(value) => onValueChange(value, "time")}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item sm={12}>
        <Typography variant={"h6"}>Note</Typography>
        <Typography>
          The "mode" indicate from when the data will be taken to craft the
          notification. Also indicates the day that will be used in case of
          notifications that can be sent x days before or after an event (like
          month closure)
        </Typography>
        <Typography>
          Ex: you can program a notification to be executed at 23:00 on the same
          day, or you can program it to be at 6:00 with data from the previous
          day.
        </Typography>
      </Grid>
    </Grid>
  );
}
