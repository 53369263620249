import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { IconButton, InputLabel, TextField, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import AddUsersDialog from "./AddUsersDialog";
import ClearIcon from "@mui/icons-material/Clear";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CustomAvatar from "../../../General/CustomAvatar";
import Grid from "@mui/material/Grid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import _ from "lodash";
import api from "../../../../Services/api";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BundledEditor from "../../../General/BundledEditor";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs/esm";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  error: {
    fontSize: 9,
    color: "red"
  },
  placeholder: {
    color: theme.palette.text.disabled
  },
  destination: {
    minHeight: 30,
    maxHeight: 100,
    overflowY: "auto",
    borderBottomStyle: "solid",
    borderBottomWidth: 0.5,
    borderBottomColor: theme.palette.text.primary
  },
  editor: {
    "& .ql-toolbar": {
      backgroundColor: "lightgray"
    },
    "& .ql-editor": {
      minHeight: 450
    }
  }
}));

const RenderUser = React.memo(({ user, onRemove }) => {
  const onRemoveClick = (event) => onRemove(user.id);
  return (
    <Grid item>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CustomAvatar
            text={user.fullName}
            picture={user.picture}
            size={"small"}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption" display="inline">
            {user.fullName}
          </Typography>
          <IconButton onClick={onRemoveClick} size="small">
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

const RenderDestination = ({
  entry,
  label,
  property,
  addUsers,
  removeUser,
  clearUsers,
  errors,
  placeholder = null,
  filterLinguistsOut = true
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const error = _.isArray(errors[property]);
  const helperText = _.isArray(errors[property]) && errors[property].join();

  return (
    <React.Fragment>
      <Grid container spacing={1} alignItems="center">
        <Grid item sm={1}>
          {label}:
        </Grid>
        <Grid item sm={10}>
          <Grid container spacing={1} className={classes.destination}>
            {entry[property].map((user, index) => (
              <RenderUser key={index} user={user} onRemove={removeUser} />
            ))}
            {placeholder !== null && (
              <Typography className={classes.placeholder}>
                {placeholder}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item sm={1} style={{ textAlign: "right" }}>
          {entry[property].length !== 0 && (
            <IconButton
              size="small"
              onClick={(event) => clearUsers(property)}
              title="Clear users"
            >
              <HighlightOffIcon />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={(event) => setOpen(true)}
            title="Add users"
          >
            <ContactMailIcon />
          </IconButton>
        </Grid>
        {error && (
          <React.Fragment>
            <Grid item sm={1}></Grid>
            <Grid item sm={10}>
              <p class="MuiFormHelperText-root Mui-error">{helperText}</p>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <AddUsersDialog
        open={open}
        setOpen={setOpen}
        onAddUsers={(ids) => addUsers(ids, property)}
        users={entry[property]}
        filterLinguistsOut={filterLinguistsOut}
      />
    </React.Fragment>
  );
};

export default function Form({
  entry = null,
  errors = [],
  setEntry,
  loading,
  submitted,
  setSaveDisabled,
  onSetDirty = null
}) {
  const classes = useStyles();
  const editorRef = useRef(null);
  const onValueChange = (value, property) => {
    setEntry({ ...entry, [property]: value });
    if (onSetDirty !== null) onSetDirty(true);
  };

  const onValueDateChange = (value, property) =>
    setEntry({
      ...entry,
      [property]: value.format("YYYY-MM-DD HH:mm:ss")
    });

  const addUsers = (ids, property) => {
    async function getItems(ids, property) {
      const params = {
        ids: ids
      };

      try {
        const response = await api.get(
          "/api/system/ad-hoc-notifications/users/details",
          {
            params: params
          }
        );
        setEntry({
          ...entry,
          [property]: [...entry[property], ...response.data.items]
        });
        if (onSetDirty !== null) onSetDirty(true);
      } catch (error) {}
    }

    const usersIds = entry[property].map((x) => x.id);
    const newUsers = ids.filter((x) => !usersIds.includes(x));
    if (newUsers.length !== 0) getItems(newUsers, property);
  };

  const removeToUser = useCallback(
    (id) => {
      setEntry((prevEntry) => ({
        ...prevEntry,
        toUsers: prevEntry.toUsers.filter((x) => x.id !== id)
      }));
      if (onSetDirty !== null) onSetDirty(true);
    },
    [onSetDirty, setEntry]
  );

  const removeBccUser = useCallback(
    (id) => {
      setEntry((prevEntry) => ({
        ...prevEntry,
        bccUsers: prevEntry.bccUsers.filter((x) => x.id !== id)
      }));
      if (onSetDirty !== null) onSetDirty(true);
    },
    [onSetDirty, setEntry]
  );

  const removeCcUser = useCallback(
    (id) => {
      setEntry((prevEntry) => ({
        ...prevEntry,
        ccUsers: prevEntry.ccUsers.filter((x) => x.id !== id)
      }));
      if (onSetDirty !== null) onSetDirty(true);
    },
    [onSetDirty, setEntry]
  );

  const removeReplyToUser = useCallback(
    (id) => {
      setEntry((prevEntry) => ({
        ...prevEntry,
        replyToUsers: prevEntry.replyToUsers.filter((x) => x.id !== id)
      }));
      if (onSetDirty !== null) onSetDirty(true);
    },
    [onSetDirty, setEntry]
  );

  const clearUsers = (property) => {
    setEntry((prevEntry) => ({
      ...prevEntry,
      [property]: []
    }));
    if (onSetDirty !== null) onSetDirty(true);
  };

  const handleEditorChange = (e) => {
    onValueChange(e.target.getContent(), "template");
  };

  return entry === null ? null : (
    <Grid container className={classes.paper} spacing={2} alignItems="center">
      <Grid item sm={12}>
        <RenderDestination
          label="To"
          property="toUsers"
          entry={entry}
          addUsers={addUsers}
          removeUser={removeToUser}
          clearUsers={clearUsers}
          errors={errors}
          placeholder={
            entry.toUsers.length === 0 ? "noreply@vengaglobal.com" : null
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderDestination
          label="Bcc"
          property="bccUsers"
          entry={entry}
          addUsers={addUsers}
          removeUser={removeBccUser}
          clearUsers={clearUsers}
          errors={errors}
          filterLinguistsOut={false}
        />
      </Grid>
      <Grid item sm={12}>
        <RenderDestination
          label="Cc"
          property="ccUsers"
          entry={entry}
          addUsers={addUsers}
          removeUser={removeCcUser}
          clearUsers={clearUsers}
          errors={errors}
        />
      </Grid>
      <Grid item sm={12}>
        <RenderDestination
          label="Reply To"
          property="replyToUsers"
          entry={entry}
          addUsers={addUsers}
          removeUser={removeReplyToUser}
          clearUsers={clearUsers}
          errors={errors}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          label="Reply to external email address"
          value={entry.replyToEmail}
          placeholder={
            entry.replyToUsers.length === 0 ? "noreply@vengaglobal.com" : ""
          }
          InputLabelProps={
            entry.replyToUsers.length === 0 || entry.replyToEmail !== ""
              ? {
                  shrink: true
                }
              : null
          }
          onChange={(event) =>
            onValueChange(event.target.value, "replyToEmail")
          }
          error={_.isArray(errors.replyToEmail)}
          helperText={
            _.isArray(errors.replyToEmail) && errors.replyToEmail.join()
          }
          disabled={loading}
          margin="none"
        />
      </Grid>

      <Grid item sm={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            fullWidth
            ampm={false}
            margin="none"
            autoOk
            emptyLabel="Send Immediate"
            clearLabel="Send Immediate"
            clearable={true}
            value={entry.executionDate}
            renderInput={(props) => <TextField {...props} label="Send date" />}
            format={"MMMM Do, YYYY  HH:mm"}
            error={_.isArray(errors.executionDate)}
            helperText={
              _.isArray(errors.executionDate) && errors.executionDate.join()
            }
            onChange={(value) => onValueDateChange(value, "executionDate")}
          />
        </LocalizationProvider>
        <Typography variant="caption" display="block" noWrap>
          {entry.timezone}
        </Typography>
      </Grid>
      <Grid item sm={4}>
        {entry.executionDate !== null && (
          <React.Fragment>
            <InputLabel>Local timezone</InputLabel>
            <Typography display="block" noWrap>
              {dayjs
                .tz(entry.executionDate, entry.timezone)
                .clone()
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format("MMMM Do, YYYY  HH:mm")}
            </Typography>
            <Typography variant="caption" display="block" noWrap>
              {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </Typography>
          </React.Fragment>
        )}
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          label="Subject"
          value={entry.subject}
          onChange={(event) => onValueChange(event.target.value, "subject")}
          error={_.isArray(errors.subject)}
          helperText={_.isArray(errors.subject) && errors.subject.join()}
          disabled={loading}
        />
      </Grid>
      <Grid item sm={12}>
        <div className={classes.editor}>
          <BundledEditor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={entry._originalTemplate}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "anchor",
                "autolink",
                "image",
                "link",
                "lists",
                "searchreplace",
                "table",
                "wordcount"
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "table | removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
            }}
            onChange={handleEditorChange}
          />
        </div>
      </Grid>
    </Grid>
  );
}
