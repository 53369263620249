import React, { useCallback, useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./Form";
import { HomeAppContext } from "../../../Home";
import Slide from "@mui/material/Slide";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);

  const getEntry = useCallback(() => {
    if (entry !== null) {
      setLoading(true);
      api
        .get(`/api/system/ad-hoc-notifications/${entry.id}`)
        .then((r) => {
          setInternalEntry({
            ...r.data,
            executionDate: dayjs.utc(r.data.executionDate).tz(r.data.timezone)
          });
          setErrors([]);
          setLoading(false);
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        });
    }
  }, [entry, homeDispatch]);

  React.useEffect(() => {
    if (open) getEntry();
  }, [getEntry, open]);

  const handleClose = () => {
    setOpen(false);
    setInternalEntry(null);
    setLoading(true);
  };

  const handleCloseSave = () => {
    const updateEntry = {
      ...internalEntry
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating Notification", loading: true }
    });
    setLoading(true);
    api
      .put(`/api/system/ad-hoc-notifications/${updateEntry.id}`, updateEntry)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Notification updated", type: "success" }
        });
        setOpen(false);
        setLoading(false);
        setInternalEntry(null);

        if (onEdit) onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">
        {"Edit Ad Hoc Notification"}
      </DialogTitle>
      <DialogContent>
        <Form
          entry={internalEntry}
          errors={errors}
          setEntry={setInternalEntry}
          loading={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
