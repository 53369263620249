//TODO check al dependencies to improve this controller
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Axios from "axios";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Chip from "@mui/material/Chip";
import CustomAvatar from "./CustomAvatarEmotion";
import { FilterOutline } from "mdi-material-ui";
import Grid from "@mui/material/Grid";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import TextField from "@mui/material/TextField";
import api from "../../Services/api";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";


const sxStyles = {
  AvatarOverrides: {
    marginRight: 10
  },
  DecorationPictureSmall: {
    marginRight: 5,
    marginTop: 0,
    marginBottom: 2
  },
  DecorationPicture: {
    marginRight: 5,
    marginBottom: 3,
    marginTop: 4
  },
  DecorationPrependChip: {
    marginBottom: "3px",
    marginTop: "4px",
    marginRight: "5px"
  },
  DecorationPrependChipSmall: {
    marginBottom: "2px",
    marginTop: "0px",
    marginRight: "5px"

  },
  MultiChip: {
    marginBottom: "3px",
    marginTop: "4px",
    marginRight: "4px"
  },
  MultiChipSmall: {
    marginBottom: 2,
    marginTop: 0,
    marginRight: 4
  }
};


const MultiDecorationPrependChip = styled(Chip)(({ theme }) => ({
  marginLeft: "-8px",
  marginRight: "5px"
}));

const PrependChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5)

}));

const AppendChip = styled(Chip)(({ theme }) => ({
  marginLeft: "auto"
}));

const MultiCheckboxTags = styled("div")(({ theme }) => ({
  fontSize: 14,
  display: "flex",
  gap: "4px",
  paddingTop: 7,
  paddingBottom: 5
}));

const MultiCheckboxTagsSmall = styled("div")(({ theme }) => ({
  fontSize: 14,
  display: "flex",
  gap: "4px",
  paddingTop: 7,
  paddingBottom: 5
}));

const MultiCheckboxOneValue = styled("span")(({ theme }) => ({
  width: "calc(100% - 40px)",
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "31px"
}));

const HighlightDot = styled("span")(({ theme }) => ({
  height: 12,
  width: 12,
  borderRadius: "50%",
  display: "inline-block",
  verticalAlign: "text-top",
  marginRight: 4
}));


const PopperMy = function(props) {
  return (
    <Popper
      {...props}
      sx={{ width: "fit-content", minWidth: props?.style?.width ?? 180 }}
      placement="bottom-end"
    />
  );
};

const initialState = {
  loading: false,
  searching: false,
  internalError: true,
  open: false,
  inputValue: "",
  options: [],
  selectedValue: null,
  related: null,
  url: "",
  cleared: false,
  filterOptions: []
};

const notCheckedIcon = (
  <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: 5 }} />
);
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ marginRight: 5 }} />
);

export default function CustomAutocomplete({
                                             value,
                                             setValue,
                                             externalOptions = null,
                                             related,
                                             dependencies = [],
                                             url = "",
                                             label,
                                             placeholder = "type some text to search...",
                                             noOptionsText = "No Options",
                                             loadingText = "Loading...",
                                             externalLoading = false,
                                             allowSelectFirst = false,
                                             allowAutoAddOption = false,
                                             allowAutoSelectFirst = false,
                                             onError = null,
                                             onLoading = null,
                                             onClear = null,
                                             multiple = false,
                                             limitTags = 3,
                                             checkbox = false,
                                             liveOptions = true,
                                             clearOnReset = true,
                                             disableClearable = false,
                                             error = false,
                                             showHelperText = false,
                                             helperText = "This field is required",
                                             margin = "none",
                                             disabled = false,
                                             forceAvatar = false,
                                             labelExpanded = false,
                                             optionsDisabled = [],
                                             optionsToHighlight = [],
                                             optionsDisabledEquality = true,
                                             size = "normal",
                                             filterOptions = [],
                                             showAppendOnValue = false,
                                             required = false,
                                             width = "auto",
                                             prependIcon = null
                                           }) {
  const firstRender = useRef(true);
  const selectedEmptyValue = multiple ? [] : null;
  const [state, setState] = useState({
    ...initialState,
    selectedValue: selectedEmptyValue,
    related: related,
    url: url,
    options: externalOptions !== null ? externalOptions : [],
    filterOptions: filterOptions.map((item) => ({
      ...item,
      value: item.default
    }))
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilterOptionChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      filterOptions: prevState.filterOptions.map((item) =>
        item.name === name ? { ...item, value: value } : item
      )
    }));
    setAnchorEl(null);
  };

  const styledInputProps = size === "small" ? { style: { fontSize: 12 } } : {};

  const emptyValue = multiple ? [] : "";
  const internalDisabled =
    related === undefined || related === "" || state.searching || disabled;

  React.useEffect(() => {
    if (externalOptions !== null) {
      setState((prevState) => ({ ...prevState, options: externalOptions }));
    }
  }, [externalOptions]);

  const onLoadingCallback = useCallback(onLoading, []);

  React.useEffect(() => {
    if (onLoadingCallback !== null) {
      onLoadingCallback(state.loading);
    }
  }, [onLoadingCallback, state.loading]);

  const adaptToLocalValue = (value) => ({
    ...value,
    append: value.append ?? null,
    prepend: value.prepend ?? null,
    text: value.text ?? value.name ?? "No description"
  });

  const setValues = (options, value) => {
    const ids = multiple ? value.map((x) => x.id) : [value.id];
    const localValue = multiple
      ? value.map((x) => adaptToLocalValue(x))
      : adaptToLocalValue(value);
    const localOptions = multiple ? localValue : [localValue];

    if (options) {
      const found = options
        .filter((item) => ids.includes(item.id))
        .reduce(
          (acc, x) => (acc.some((y) => y.id === x.id) ? acc : [...acc, x]),
          []
        );
      if (found.length !== 0) {
        if (multiple) {
          setState((prevState) => ({ ...prevState, selectedValue: found }));
        } else {
          setState((prevState) => ({ ...prevState, selectedValue: found[0] }));
        }
      } else if (allowAutoAddOption && options.length !== 0) {
        setState((prevState) => ({
          ...prevState,
          options: [...localOptions, ...options],
          selectedValue: localValue
        }));
      } else if (allowSelectFirst && options.length !== 0) {
        setState((prevState) => ({ ...prevState, selectedValue: options[0] }));
      } else {
        setValue(emptyValue);
      }
    } else {
      setValue(emptyValue);
    }
  };

  const searchAndSet = (value) => {
    setState((prevState) => ({ ...prevState, searching: true, loading: true }));

    const searchIds = multiple ? value.map((x) => x.id) : [value.id];
    if (externalOptions === null) {
      setState((prevState) => ({ ...prevState, options: [] }));
      let params = {};
      if (liveOptions) {
        params.ids = searchIds;
      }

      if (isArray(related) && related.length > 0) {
        params.related = related.map((x) => x.id);
      } else if (isObject(related) && related.hasOwnProperty("id")) {
        params.related = [related.id];
      }

      if (state.filterOptions.length !== 0) {
        params.options = state.filterOptions.map((x) => ({
          name: x.name,
          value: x.value
        }));
      }

      fetch(url, params, {}, (results) => {
        setState((prevState) => ({ ...prevState, options: results || [] }));
        setValues(results, value);

        setState((prevState) => ({
          ...prevState,
          searching: false,
          loading: false
        }));
      });
    } else {
      setValues(state.options, value);
      setState((prevState) => ({
        ...prevState,
        searching: false,
        loading: false
      }));
    }
  };

  const isArray = (value) => {
    return (
      value &&
      typeof value == "object" &&
      typeof value.length == "number" &&
      toString.call(value) === "[object Array]"
    );
  };
  const isObject = (value) => {
    return value && typeof value == "object";
  };

  const searchAndAutoSelectFirst = () => {
    setState((prevState) => ({ ...prevState, searching: true, loading: true }));

    if (externalOptions === null) {
      setState((prevState) => ({ ...prevState, options: [] }));
      let params = {};
      if (isArray(related) && related.length > 0) {
        params.related = related.map((x) => x.id);
      } else if (isObject(related) && related.hasOwnProperty("id")) {
        params.related = [related.id];
      }

      if (state.filterOptions.length !== 0) {
        params.options = state.filterOptions.map((x) => ({
          name: x.name,
          value: x.value
        }));
      }

      fetch(url, params, {}, (results) => {
        const newValues = multiple ? [results[0]] : results[0];
        setState((prevState) => ({
          ...prevState,
          options: results || [],
          selectedValue: newValues ?? emptyValue,
          searching: false,
          loading: false
        }));
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedValue: multiple ? prevState.options : prevState.options[0],
        searching: false,
        loading: false
      }));
    }
  };

  const compareSelectedValue = (value) => {
    if (multiple) {
      return (
        state.selectedValue.length === value.length &&
        state.selectedValue
          .map((x) => x.id)
          .some((y) => value.map((z) => z.id).includes(y))
      );
    } else {
      return value.id === state.selectedValue.id;
    }
  };

  const isEmptyValue = (value) => {
    return multiple ? value.length === 0 : value === "";
  };

  const isSelectedEmptyValue = useCallback((value) => {
    return multiple ? value.length === 0 : value === null;
  }, []);

  React.useEffect(() => {
    if (!allowAutoSelectFirst && (value === undefined || isEmptyValue(value))) {
      setState((prevState) => ({
        ...prevState,
        selectedValue: selectedEmptyValue
      }));
    } else if (
      value !== undefined &&
      !isEmptyValue(value) &&
      (isSelectedEmptyValue(state.selectedValue) ||
        (!isSelectedEmptyValue(state.selectedValue) &&
          !compareSelectedValue(value)))
    ) {
      searchAndSet(value);
    } else if (!state.cleared && allowAutoSelectFirst && isEmptyValue(value)) {
      searchAndAutoSelectFirst();
    }
  }, [value]);

  const sameRelated = (externalRelated, localRelated) => {
    if (isArray(externalRelated) && isArray(localRelated)) {
      return (
        externalRelated.length === localRelated.length &&
        localRelated.length ===
        externalRelated.filter((x) => localRelated.some((y) => y.id === x.id))
          .length
      );
    } else if (isObject(externalRelated) && isObject(localRelated)) {
      return externalRelated.id === localRelated.id;
    }
    return false;
  };

  React.useEffect(() => {
    if (
      related !== undefined &&
      related !== null &&
      ((related === "" && state.related !== null) ||
        (related !== "" && state.related === null) ||
        (related !== "" &&
          state.related !== null &&
          !sameRelated(related, state.related)))
    ) {
      if (!isEmptyValue(related)) {
        setState((prevState) => ({
          ...prevState,
          cleared: false,
          related: related
        }));

        if (value !== undefined && !isEmptyValue(value)) {
          searchAndSet(value);
        } else if (allowAutoSelectFirst && value === "") {
          searchAndAutoSelectFirst();
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          related: null,
          selectedValue: selectedEmptyValue
        }));
      }
    }
  }, [related]);

  React.useEffect(() => {
    if (url !== state.url) {
      setState((prevState) => ({
        ...prevState,
        cleared: false,
        url: url
      }));

      if (value !== undefined && !isEmptyValue(value)) {
        searchAndSet(value);
      } else if (allowAutoSelectFirst && value === "") {
        searchAndAutoSelectFirst();
      }
    }
  }, [url]);

  React.useEffect(() => {
    if (dependencies.length)
      setState((prevState) => ({
        ...prevState,
        selectedValue: selectedEmptyValue
      }));

  }, dependencies);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (multiple) {
        if (state.selectedValue.length !== 0) {
          setValue(state.selectedValue);
        } else {
          if (value.length !== 0) {
            setValue(emptyValue);
          }
        }
      } else {
        if (state.selectedValue !== null) {
          setValue(state.selectedValue);
        } else if (value !== emptyValue && !allowAutoSelectFirst) {
          setValue(emptyValue);
        } else {
          setValue(emptyValue);
        }
      }
    }
  }, [state.selectedValue]);

  const handleAutocompleteChange = (event, newValue) => {
    setState((prevState) => ({ ...prevState, selectedValue: newValue }));
  };

  const handleOnInputChange = (event, value, reason) => {
    if (reason === "input") {
      setState((prevState) => ({ ...prevState, inputValue: value }));
    } else if (
      reason === "reset" &&
      (value !== "" || (clearOnReset && value === ""))
    ) {
      setState((prevState) => ({
        ...prevState,
        inputValue: value
      }));
    } else if (reason === "clear") {
      setState((prevState) => ({
        ...prevState,
        inputValue: "",
        cleared: true,
        open: true
      }));
      if (onClear !== null) onClear();
    }
  };

  React.useEffect(() => {
    const source = Axios.CancelToken.source();

    if (
      state.open &&
      (liveOptions ||
        (!liveOptions && state.options.length === 0) ||
        state.related !== related)
    ) {
      let active = true;

      if (state.inputValue !== "") {
        return undefined;
      }

      if (!state.open) {
        return undefined;
      }

      setState((prevState) => ({ ...prevState, options: [] }));

      let params = {};
      if (liveOptions) {
        params.query = state.inputValue;
      }

      if (isArray(related) && related.length > 0) {
        params.related = related.map((x) => x.id);
      } else if (isObject(related) && related.hasOwnProperty("id")) {
        params.related = [related.id];
      }

      if (state.filterOptions.length !== 0) {
        params.options = state.filterOptions.map((x) => ({
          name: x.name,
          value: x.value
        }));
      }

      setState((prevState) => ({ ...prevState, loading: true }));
      const timer = setTimeout(
        fetch(url, params, { cancelToken: source.token }, (results) => {
          if (active) {
            setState((prevState) => ({ ...prevState, options: results || [] }));
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        }),
        1500
      );

      return () => {
        active = false;
        clearTimeout(timer);
        source.cancel("Cancelling in cleanup");
      };
    }
  }, [state.open]);

  React.useEffect(() => {
    if (
      liveOptions ||
      (!liveOptions && state.options.length === 0) ||
      state.related !== related
    ) {
      let active = true;

      if (!state.open) {
        return undefined;
      }

      setState((prevState) => ({ ...prevState, options: [] }));

      let params = {};
      if (liveOptions) {
        params.query = state.inputValue;
      }

      if (isArray(related) && related.length > 0) {
        params.related = related.map((x) => x.id);
      } else if (isObject(related) && related.hasOwnProperty("id")) {
        params.related = [related.id];
      }

      if (state.filterOptions.length !== 0) {
        params.options = state.filterOptions.map((x) => ({
          name: x.name,
          value: x.value
        }));
      }

      setState((prevState) => ({ ...prevState, loading: true }));
      const source = Axios.CancelToken.source();
      const timer = setTimeout(() => {
        fetch(url, params, { cancelToken: source.token }, (results) => {
          if (active) {
            setState((prevState) => ({ ...prevState, options: results || [] }));
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        });
      }, 1500);

      return () => {
        active = false;
        clearTimeout(timer);
      };
    }
  }, [state.inputValue]);

  const fetch = (url, params, options, callback) => {
    setState((prevState) => ({ ...prevState, internalError: false }));
    api
      .get(
        url,
        {
          params: params
        },
        options
      )
      .then((results) => {
        if (callback instanceof Function) callback(results.data);
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.log("axios", error.message);
        } else if (onError !== null) {
          onError(error);
          setState((prevState) => ({ ...prevState, internalError: true }));
        } else {
          setState((prevState) => ({ ...prevState, internalError: true }));
          console.log(error);
        }
      });
  };

  const liveFilter = (options, state) => {
    return options;
  };

  const stripDiacritics = (string) => {
    return typeof string.normalize !== "undefined"
      ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      : string;
  };

  const stringifyOption = (option) => `${option.prepend} ${option.text}`;

  const localFilter = (options, { inputValue, getOptionLabel }) => {
    const ignoreAccents = true;
    const ignoreCase = true;
    const limit = false;
    const matchFrom = "any";
    const stringify = stringifyOption;
    const trim = false;

    let input = trim ? inputValue.trim() : inputValue;
    if (ignoreCase) {
      input = input.toLowerCase();
    }
    if (ignoreAccents) {
      input = stripDiacritics(input);
    }

    const filteredOptions = options.filter((option) => {
      let candidate = (stringify || getOptionLabel)(option);
      if (ignoreCase) {
        candidate = candidate.toLowerCase();
      }
      if (ignoreAccents) {
        candidate = stripDiacritics(candidate);
      }

      return matchFrom === "start"
        ? candidate.indexOf(input) === 0
        : candidate.indexOf(input) > -1;
    });

    return typeof limit === "number"
      ? filteredOptions.slice(0, limit)
      : filteredOptions;
  };

  const getLimitTagsText = (more) => {
    const tooltip = state.selectedValue.slice(
      state.selectedValue.length - more,
      state.selectedValue.length
    );
    return (
      <Tooltip
        title={
          <Grid container spacing={1}>
            {tooltip.map((x, index) => (
              <Grid item key={index}>
                <b>{x.prepend}</b> {x.text}
              </Grid>
            ))}
          </Grid>
        }
      >
        <span>+{more}</span>
      </Tooltip>
    );
  };

  const optionsToggle = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };

  return (
    <Autocomplete
      PopperComponent={PopperMy}
      multiple={multiple}
      limitTags={limitTags}
      getLimitTagsText={getLimitTagsText}
      disableCloseOnSelect={multiple}
      open={state.open}
      inputValue={state.inputValue}
      onOpen={() => {
        setState((prevState) => ({ ...prevState, open: true }));
      }}
      onClose={() => {
        const signalClear = clearOnReset ? {} : { inputValue: "" };
        setState((prevState) => ({
          ...prevState,
          open: false,
          ...signalClear
        }));
      }}
      onChange={handleAutocompleteChange}
      onInputChange={handleOnInputChange}
      groupBy={(option) => option.group}
      value={state.selectedValue}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.text
      }
      noOptionsText={state.internalError ? "Internal Error" : noOptionsText}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      getOptionDisabled={(option) =>
        optionsDisabledEquality
          ? optionsDisabled.some((x) => x.id === option.id)
          : !optionsDisabled.some((x) => x.id === option.id)
      }
      filterOptions={liveOptions ? liveFilter : localFilter}
      filterSelectedOptions={multiple && !checkbox}
      options={state.options}
      loading={state.loading}
      loadingText={loadingText}
      disabled={internalDisabled}
      disableClearable={disableClearable || state.loading || externalLoading}
      clearOnEscape={!disableClearable}
      renderTags={(value, getTagProps) =>
        checkbox ? (
          value.length === 1 ? (
            <MultiCheckboxOneValue
              onClick={optionsToggle}
            >
              {value[0].text}
            </MultiCheckboxOneValue>
          ) : (
            size === "small" ?
              <MultiCheckboxTagsSmall onClick={optionsToggle}>
                <LibraryAddCheckIcon fontSize={"inherit"} />
                {value.length} selected
              </MultiCheckboxTagsSmall>
              :
              <MultiCheckboxTags onClick={optionsToggle}>
                <LibraryAddCheckIcon fontSize={"inherit"} />
                {value.length} selected
              </MultiCheckboxTags>
          )
        ) : (
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={
                <React.Fragment>
                  {option.prepend !== "" &&
                  option.prepend !== null &&
                  option.prepend !== undefined ? (
                    <MultiDecorationPrependChip
                      size="small"
                      label={option.prepend}
                    />
                  ) : null}
                  {optionsToHighlight
                    .filter((x) => x.id === option.id)
                    .map((y, index) => (
                      <HighlightDot
                        key={index}
                        sx={{ backgroundColor: y.color }}
                        title={y.tooltip}
                      />
                    ))}
                  {option.text}
                </React.Fragment>
              }
              size="small"
              {...getTagProps({ index })}
              sx={size === "small" ? sxStyles.MultiChipSmall : sxStyles.MultiChip}
            />
          ))
        )
      }
      renderOption={(props, option, { selected }) => (
        <Box component="li"  {...props} key={option.id}>
          <Grid container alignContent="center">
            {checkbox && (
              <Grid item>{selected ? checkedIcon : notCheckedIcon}</Grid>
            )}
            <Grid item style={{ flex: 1 }}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container wrap={"nowrap"} alignContent="center">
                    {option.prepend !== null && option.prepend !== undefined ? (
                      <PrependChip
                        size="small"
                        label={option.prepend}
                      />
                    ) : null}
                    {(option.picture !== null && option.picture !== undefined) ||
                    forceAvatar ? (
                      <CustomAvatar
                        picture={option.picture}
                        text={option.text}
                        overrides={sxStyles.AvatarOverrides}
                      />
                    ) : null}
                    <Typography display="inline">{option.text}</Typography>
                  </Grid>
                </Grid>
                {option.description &&
                  option.description.split("\n").map((x, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant="caption">{x}</Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            {option.append !== null && option.append !== undefined ? (
              <AppendChip
                size="small"
                variant={"outlined"}
                label={option.append}
              />
            ) : null}
          </Grid>
        </Box>
      )}
      renderInput={(params) => (
        <Grid container alignItems="flex-end">
          <Grid item style={{ flex: 1 }}>
            <TextField
              {...params}
              label={label + (required ? "*" : "")}
              fullWidth={width === "auto"}
              style={width !== "auto" ? { width: width } : null}
              error={error}
              helperText={error || showHelperText ? helperText : ""}
              margin={margin}
              value={state.inputValue}
              placeholder={
                multiple
                  ? isEmptyValue(state.selectedValue)
                    ? placeholder
                    : null
                  : placeholder
              }
              InputLabelProps={{
                ...styledInputProps,
                shrink:
                  labelExpanded ||
                  (multiple
                    ? state.selectedValue.length !== 0 || state.open
                    : state.selectedValue !== selectedEmptyValue ||
                    state.inputValue !== "" ||
                    state.open)
              }}
              InputProps={{
                ...params.InputProps,
                ...styledInputProps,
                startAdornment: (
                  <React.Fragment>
                    {prependIcon}
                    {!multiple &&
                    state.selectedValue &&
                    state.selectedValue.prepend !== null &&
                    state.selectedValue.prepend !== undefined ? (
                      <Chip
                        size="small"
                        label={state.selectedValue.prepend}
                        sx={
                          size === "small"
                            ? sxStyles.DecorationPrependChipSmall
                            : sxStyles.DecorationPrependChip
                        }
                      />
                    ) : null}
                    {!multiple &&
                    state.selectedValue &&
                    ((state.selectedValue.picture !== null &&
                        state.selectedValue.picture !== undefined) ||
                      forceAvatar) ? (
                      <CustomAvatar
                        picture={state.selectedValue.picture}
                        text={state.selectedValue.text}
                        sx={
                          size === "small"
                            ? sxStyles.DecorationPictureSmall
                            : sxStyles.DecorationPicture
                        }
                      />
                    ) : null}

                    {params.InputProps.startAdornment}
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {showAppendOnValue &&
                    !multiple &&
                    state.selectedValue &&
                    state.selectedValue.append !== null &&
                    state.selectedValue.append !== undefined ? (
                      <Chip
                        size="small"
                        label={state.selectedValue.append}
                        sx={
                          size === "small"
                            ? sxStyles.DecorationPrependChipSmall
                            : sxStyles.DecorationPrependChip
                        }
                      />
                    ) : null}
                    {state.loading || externalLoading ? (
                      <span style={{ position: "absolute", right: 30, top: 9 }}>
                        <CircularProgress color="inherit" size={14} />
                      </span>
                    ) : null}

                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          </Grid>
          {state.filterOptions.length !== 0 && (
            <Grid item>
              <IconButton size="small" onClick={handleClick}>
                <FilterOutline fontSize="inherit" />
              </IconButton>
              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem disabled>
                  Filter options
                </MenuItem>
                {state.filterOptions.map((menuItem, index) => (
                  <MenuItem key={index} dense={true}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={menuItem.value}
                          onChange={(event) =>
                            handleFilterOptionChange(
                              menuItem.name,
                              event.target.checked
                            )
                          }
                          name={menuItem.name}
                        />
                      }
                      label={menuItem.description}
                    />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        </Grid>
      )}
    />
  );
}
