import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@mui/material";
import Create from "./Create";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import MyDataGrid from "../../../General/Pagination/MyDataGrid";

const initialState = {
  loading: true,
  openCreate: false,
  refresh: 0
};

export default function List(props) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = React.useState(initialState);

  const tableOptions = {
    columns: [
      {
        name: "ToSearch",
        description: "To",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "Subject",
        description: "Subject",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedAt",
        description: "Created At",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "ExecutionDate",
        description: "Send Date",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Status",
        description: "Status",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "CreatedAt", order: "-" }],
    additionalFilters: [
      {
        name: "ToSearch",
        description: "To",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 4
      },
      {
        name: "Subject",
        description: "Subject",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0
        },
        size: 3
      },
      {
        name: "Status",
        description: "Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/system/mail-logs/status/dropdown`,
            multiple: false,
            liveOptions: false
          }
        },
        size: 2
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "System - Ad Hoc Notifications"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const setStateProperty = (value, property) =>
    setState((prevState) => ({ ...prevState, [property]: value }));

  const refreshItems = () => {
    setState((prevState) => ({ ...prevState, refresh: prevState.refresh + 1 }));
  };

  const handleClickCreate = () => {
    setState((prevState) => ({ ...prevState, openCreate: true }));
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickCreate}
            startIcon={<AddBoxIcon />}
          >
            New Ad Hoc Notification
          </Button>
        </Grid>
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/system/ad-hoc-notifications"}
            label={"ad hoc notifications"}
            refresh={state.refresh}
            renderItem={(item, selectionProperties, selectionCheckbox) => (
              <Entry key={item.id} entry={item} refreshItems={refreshItems} />
            )}
          />
        </Grid>
      </Grid>
      <Create
        open={state.openCreate}
        setOpen={(value) => setStateProperty(value, "openCreate")}
        onCreate={refreshItems}
      />
    </React.Fragment>
  );
}
