import Grid from "@mui/material/Grid";
import React from "react";
import Typography from "@mui/material/Typography";

const Blank = () => {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"} spacing={4}>
      <Grid item>
        <Typography variant={"h6"}>
          No permissions set for your user, please contact an administrator so a
          proper role can be assigned
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Blank;
