import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, {useCallback, useState} from "react";

import Button from "@mui/material/Button";
import CustomAvatar from "../General/CustomAvatar";
import Divider from "@mui/material/Divider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Link as RouterLink, useHistory} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import UserPreferences from "./UserPreferences";
import withStyles from '@mui/styles/withStyles';

const WhiteKeyboardArrowDownIcon = withStyles((theme) => ({
  root: {
    color: "white",
  },
}))(KeyboardArrowDownIcon);

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginBottom: 5,
  },
  userInformation: {
    padding: 10,
  },
  divider: {
    marginBottom: 10,
  },
}));

const StyledMenu = withStyles({
  paper: {},
})((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const initialState = {
  fullName: "",
  picture: "",
  email: "",
  role: "",
  roleDescription: "",
  theme: "Auto",
};

export default function UserMenu({ userProfile }) {
  const classes = useStyles();
  const history = useHistory();

  const [profile, setProfile] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  const loadProfile = useCallback(() => {
    if (userProfile !== null && userProfile !== undefined) {
      setProfile({ ...userProfile });
    } else {
      setProfile(initialState);
    }
  }, [userProfile]);

  React.useEffect(() => loadProfile(), [loadProfile]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenDialog = useCallback(
    (tabIndex = 0) => {
      setAnchorEl(null);
      setProfile({ ...userProfile });
      setDefaultTabIndex(tabIndex);
      setOpen(true);
    },
    [userProfile]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (params.get("showUserPreferences")) handleOpenDialog(1);
  }, [handleOpenDialog, history.location.search]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant={"text"} onClick={handleClick}>
        <CustomAvatar
          text={profile.fullName}
          picture={profile.picture}
          size="normal"
        />
        <WhiteKeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          className={classes.userInformation}
        >
          <Grid item>
            <CustomAvatar
              text={profile.fullName}
              picture={profile.picture}
              size="normal"
              className={classes.avatar}
            />
          </Grid>
          <Grid item>
            <Typography>{profile.fullName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{profile.email}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {profile.roleDescription ?? profile.role}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <MenuItem  onClick={(event) => handleOpenDialog(0)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Preferences" />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/logout"
          onClick={handleClose}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </StyledMenu>
      {open && (
        <UserPreferences
          open={open}
          setOpen={setOpen}
          defaultTabIndex={defaultTabIndex}
        />
      )}
    </div>
  );
}
