import { Avatar, Collapse } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import {
  GeAdHocNotification,
  GeLogIcon,
  GeMailLogs,
  GeNotificationIcon,
  GeReports,
  GeSettings,
  GeSettingsGeneral,
  GeTestsIcon,
  GeUsersIcon
} from "./General/GeneralIcons";
import React, { useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import { userStore } from "../Services/store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const useStyles = makeStyles((theme) => ({
  active: {
    borderLeftStyle: "solid",
    borderLeftWidth: "4px",
    borderLeftColor: theme.palette.secondary.main,
    paddingLeft: "12px"
  },
  activeMenu: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: theme.palette.secondary.main,
    paddingLeft: "14px"
  },
  avatarIcon: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    width: 24
  },
  nested: {
    paddingLeft: theme.spacing(3)
  },
  nestedActive: {
    paddingLeft: theme.spacing(3) - 4
  },
  listItemIcon: {
    minWidth: 40
  }
}));

const RouteType = {
  Internal: "internal",
  External: "external",
  Action: "action",
  Menu: "menu"
};

const onHangfireUrl = () => {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = `${process.env.REACT_APP_BACKEND_URL}/hangfire?access_token=${userStore.token}`;
  a.click();
};

const HangFireIcon = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    width: 24
  }
}))(Avatar);

const adminRoutes = [
  {
    text: "Users",
    type: RouteType.Internal,
    to: "/users",
    icon: <GeUsersIcon />
  },
  {
    text: "Tracker",
    type: RouteType.Internal,
    to: "/tracker",
    icon: <GeUsersIcon />
  } /*,
  {
    text: "Reports",
    type: RouteType.Menu,
    icon: <GeReports />,
    items: []
  },
  {
    text: "Tests",
    type: RouteType.Menu,
    icon: <GeTestsIcon />,
    items: [
      {
        text: "Notifications",
        type: RouteType.Internal,
        to: "/tests/notifications",
        icon: <GeNotificationIcon />
      }
    ]
  }*/,
  {
    text: "Logs",
    type: RouteType.Internal,
    to: "/logs",
    icon: <GeLogIcon />
  },
  {
    text: "System",
    type: RouteType.Menu,
    icon: <GeSettings />,
    items: [
      {
        text: "General",
        type: RouteType.Internal,
        to: "/system/settings",
        icon: <GeSettingsGeneral />
      },
      {
        text: "Notifications",
        type: RouteType.Internal,
        to: "/system/notifications",
        icon: <GeNotificationIcon />
      },
      {
        text: "Ad hoc notifications",
        type: RouteType.Internal,
        to: "/system/ad-hoc-notifications",
        icon: <GeAdHocNotification />
      },
      {
        text: "Mail Logs",
        type: RouteType.Internal,
        to: "/system/mail-logs",
        icon: <GeMailLogs />
      },
      {
        text: "Vendor Action Logs",
        type: RouteType.Internal,
        to: "/vendor-action-logs",
        icon: <GeMailLogs />
      }
    ]
  },
  {
    text: "Hangfire",
    type: RouteType.Action,
    action: onHangfireUrl,
    icon: <HangFireIcon variant="square">H</HangFireIcon>
  }
];

const powerUserRoutes = [
  {
    text: "Users",
    type: RouteType.Internal,
    to: "/users",
    icon: <GeUsersIcon />
  },
  {
    text: "Reports",
    type: RouteType.Menu,
    icon: <GeReports />,
    items: []
  },
  {
    text: "Logs",
    type: RouteType.Internal,
    to: "/logs",
    icon: <GeLogIcon />
  },
  {
    text: "System",
    type: RouteType.Menu,
    icon: <GeSettings />,
    items: [
      {
        text: "Notifications",
        type: RouteType.Internal,
        to: "/system/notifications",
        icon: <GeNotificationIcon />
      },
      {
        text: "Ad hoc notifications",
        type: RouteType.Internal,
        to: "/system/ad-hoc-notifications",
        icon: <GeAdHocNotification />
      },
      {
        text: "Mail Logs",
        type: RouteType.Internal,
        to: "/system/mail-logs",
        icon: <GeMailLogs />
      }
    ]
  },
  {
    text: "Hangfire",
    type: RouteType.Action,
    action: onHangfireUrl,
    icon: <HangFireIcon variant="square">H</HangFireIcon>
  }
];

const userRoutes = [
  {
    text: "Logout",
    type: RouteType.Internal,
    to: "/logout",
    icon: <ExitToAppIcon />
  },
  {
    text: "Tracker",
    type: RouteType.Internal,
    to: "/tracker",
    icon: <GeUsersIcon />
  }
];
const baseSpacing = 30;

const RenderInternal = ({ item, closeDrawer, menuOpen = true, depth = 0 }) => {
  const classes = useStyles();
  const history = useHistory();
  const active = history.location.pathname === item.to;

  return (
    <ListItem
      button
      component={RouterLink}
      onClick={closeDrawer}
      to={item.to}
      className={active ? classes.active : null}
      sx={
        depth !== 0
          ? {
              paddingLeft:
                baseSpacing * depth - (active ? 4 : 0) + "px!important;"
            }
          : {}
      }
    >
      <ListItemIcon className={classes.listItemIcon} title={item.text}>
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primary={menuOpen ? item.text : ""}
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItem>
  );
};

const RenderExternal = ({ item, menuOpen = true, depth = 0 }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ListItem
      button
      component={"a"}
      href={item.to}
      rel="noreferrer"
      target="_blank"
      style={
        depth !== 0
          ? { paddingLeft: baseSpacing * depth + "px!important;" }
          : {}
      }
    >
      <ListItemIcon className={classes.listItemIcon} title={item.text}>
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primary={menuOpen ? item.text : ""}
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItem>
  );
};

const RenderAction = ({ item, menuOpen = true, depth = 0 }) => {
  const classes = useStyles();
  const onClick = () => {
    if (item.action !== null) item.action();
  };

  return (
    <ListItem
      button
      onClick={onClick}
      sx={
        depth !== 0
          ? { paddingLeft: baseSpacing * depth + "px!important;" }
          : {}
      }
    >
      <ListItemIcon className={classes.listItemIcon} title={item.text}>
        {item.icon}
      </ListItemIcon>
      <ListItemText
        primary={menuOpen ? item.text : ""}
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItem>
  );
};

const RenderMenu = ({ item, closeDrawer, menuOpen = true, depth = 0 }) => {
  const classes = useStyles();
  const history = useHistory();
  const isOpen = (item) => {
    return item.items.some((x) =>
      x.type === RouteType.Menu ? isOpen(x) : history.location.pathname === x.to
    );
  };
  const [open, setOpen] = React.useState(isOpen(item));

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleClick}
        className={isOpen(item) ? classes.activeMenu : null}
        sx={
          depth !== 0
            ? {
                paddingLeft:
                  baseSpacing * depth - (isOpen(item) ? 2 : 0) + "px!important;"
              }
            : {}
        }
      >
        <ListItemIcon className={classes.listItemIcon} title={item.text}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={menuOpen ? item.text : ""}
          primaryTypographyProps={{ variant: "body2" }}
        />
        {menuOpen && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.items.map((subItems, index) =>
            subItems.type === RouteType.Menu ? (
              <RenderMenu
                key={index}
                item={subItems}
                closeDrawer={closeDrawer}
                menuOpen={menuOpen}
                depth={depth + 1}
              />
            ) : subItems.type === RouteType.Internal ? (
              <RenderInternal
                key={index}
                item={subItems}
                closeDrawer={closeDrawer}
                depth={depth + 1}
                menuOpen={menuOpen}
              />
            ) : (
              <RenderExternal key={index} item={subItems} depth={depth + 1} />
            )
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default function Sidebar({
  userProfile,
  handleDrawerClose = null,
  menuOpen = true
}) {
  const [role, setRole] = useState("");

  const closeDrawer = () => {
    if (handleDrawerClose !== null) handleDrawerClose();
  };

  useEffect(() => {
    if (userProfile !== null && userProfile !== undefined) {
      setRole(userProfile.role);
    } else {
      setRole(null);
    }
  }, [userProfile]);

  const RenderRoutes = ({ items }) => (
    <React.Fragment>
      {items.map((item, index) =>
        item.type === RouteType.Menu ? (
          <RenderMenu
            key={index}
            item={item}
            closeDrawer={closeDrawer}
            menuOpen={menuOpen}
          />
        ) : item.type === RouteType.Internal ? (
          <RenderInternal
            key={index}
            item={item}
            closeDrawer={closeDrawer}
            menuOpen={menuOpen}
          />
        ) : item.type === RouteType.External ? (
          <RenderExternal key={index} item={item} menuOpen={menuOpen} />
        ) : (
          <RenderAction key={index} item={item} menuOpen={menuOpen} />
        )
      )}
    </React.Fragment>
  );

  return (
    <List component="nav">
      {
        {
          Admin: <RenderRoutes items={adminRoutes} />,
          PowerUser: <RenderRoutes items={powerUserRoutes} />,
          User: <RenderRoutes items={userRoutes} />
        }[role]
      }
    </List>
  );
}
