import { Button, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import Edit from "./Edit";
import { GeEditIcon } from "../../../General/GeneralIcons";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";

const PropertyRow = ({ label, value }) => {
  return (
    <React.Fragment>
      <Grid item sm={5}>
        {label}
      </Grid>
      <Grid item sm={7}>
        {value}
      </Grid>
    </React.Fragment>
  );
};

const initialState = {
  loading: true,
  settings: null,
  openEdit: false
};

export default function SystemSettings() {
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "System settings"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const getEntry = useCallback(() => {
    setState((prevState) => ({ ...prevState, loading: false }));
    api
      .get("/api/system/settings")
      .then((r) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          settings: r.data
        }));
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  React.useEffect(() => {
    getEntry();
  }, [getEntry]);

  const handleClickEdit = () => {
    setState((prevState) => ({
      ...prevState,
      openEdit: true
    }));
  };

  const setStateProperty = (value, property) =>
    setState((prevState) => ({ ...prevState, [property]: value }));

  const refreshItems = () => {
    getEntry();
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={1}></Grid>

        <Grid item sm={5}>
          {state.settings && (
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h5">System settings</Typography>
              </Grid>

              <Grid item sm={12}>
                <Typography variant="h6">Global settings</Typography>
              </Grid>
              <PropertyRow
                label="System Timezone"
                value={state.settings.globalTimezone.name}
              />
              <Grid item sm={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickEdit}
                  startIcon={<GeEditIcon />}
                >
                  Edit settings
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>
      <Edit
        open={state.openEdit}
        setOpen={(value) => setStateProperty(value, "openEdit")}
        onEdit={refreshItems}
      />
    </React.Fragment>
  );
}
