import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const TimerProgress = ({ time }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = time / 1000;
      setProgress((prevProgress) => prevProgress + 100 / seconds > 100 ? 0 : prevProgress + 100 / seconds);
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  }, [time]);

  return (
    <>
      <RefreshIcon sx={{ position: "absolute", fontSize: "18px" }} />
      <CircularProgress
        size={20}
        value={progress}
        valueBuffer={progress}
        variant="determinate"
        color={"primary"}
      />
    </>
  );
};

export default TimerProgress;
