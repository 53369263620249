import {
  Button,
  Checkbox,
  CircularProgress,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GeCheckFalse, GeCheckTrue } from "../../General/GeneralIcons";
import React, { useContext } from "react";

import CustomAvatar from "../../General/CustomAvatar";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../Home";
import _ from "lodash";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";
import { dayjs } from "../../../Services/dateUtil";

const useStyles = makeStyles((theme) => ({
  htmlTemplate: {
    "& .myTable": {
      color: theme.palette.mode === "dark" ? "black" : "inherit"
    }
  }
}));

const initialStateSubmittedHoursTestTest = {
  loading: false,
  loadingUsers: true,
  nowDate: new Date(),
  sendEmails: false,
  redirectEmails: true,
  emailForRedirection: "",
  selected: {},
  result: "",
  users: [],
  errors: []
};

const WelcomeMessageTest = ({ tabTransitionEnded }) => {
  const classes = useStyles();

  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = React.useState(initialStateSubmittedHoursTestTest);

  React.useEffect(() => {
    if (tabTransitionEnded) {
      setState((prevState) => ({
        ...prevState,
        loadingUsers: true
      }));
      api
        .get(`/api/notifications/test/welcome-message/users`)
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            users: response.data,
            selected: {},
            loadingUsers: false
          }));
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch, setErrors);
          setState((prevState) => ({
            ...prevState,
            loadingUsers: false
          }));
        });
    }
  }, [homeDispatch, tabTransitionEnded]);

  const handleValueChange = (property, value) => {
    setState((prevState) => ({ ...prevState, [property]: value }));
  };

  const handleValueToggle = (property) => {
    setState((prevState) => ({
      ...prevState,
      [property]: !prevState[property]
    }));
  };

  const toggleSelection = (item) => {
    setState((prevState) => ({
      ...prevState,
      selected: {
        ...prevState.selected,
        [item["userId"]]:
          prevState.selected[item["userId"]] === undefined
            ? true
            : !prevState.selected[item["userId"]]
      }
    }));
  };

  const setErrors = (errors) => {
    setState((prevState) => ({
      ...prevState,
      errors: errors
    }));
  };

  const executeTest = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Testing Submitted hours", loading: true }
    });
    setState((prevState) => ({
      ...prevState,
      errors: [],
      loading: true
    }));
    const params = (({
      selected,
      sendEmails,
      redirectEmails,
      emailForRedirection
    }) => ({
      usersIds: Object.keys(selected).reduce((acc, current) => {
        if (selected[current]) return [...acc, current];
        return acc;
      }, []),
      sendEmails,
      redirectEmails,
      emailForRedirection
    }))(state);
    api
      .post(`/api/notifications/test/welcome-message`, params, {
        timeout: 40000
      })
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: response.data.message, type: response.data.status }
        });
        setState((prevState) => ({
          ...prevState,
          result: decodeURIComponent(
            response.data.plainResult.replace(/\+/g, "%20")
          ),
          loading: false
        }));
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch, setErrors);
        setState((prevState) => ({
          ...prevState,
          loading: false
        }));
      });
  };

  return (
    <Grid container spacing={2} justifyContent={"space-between"}>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>Note</Typography>
            <Typography>
              The system is generating real reset password tokens, please
              <b> handle with care</b>.
            </Typography>
            <Typography>
              The default expiration of a token is 24 hours.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Send emails</Typography>
          </Grid>
          <Grid item xs={6}>
            <Switch
              checked={state.sendEmails}
              onClick={(event) => handleValueToggle("sendEmails")}
              color="secondary"
              disabled={state.loading}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Redirect emails</Typography>
          </Grid>
          <Grid item xs={6}>
            <Switch
              checked={state.redirectEmails}
              onClick={(event) => handleValueToggle("redirectEmails")}
              color="secondary"
              disabled={state.loading || !state.sendEmails}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>email</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={state.emailForRedirection}
              onChange={(event) =>
                handleValueChange("emailForRedirection", event.target.value)
              }
              disabled={state.loading || !state.sendEmails}
              error={_.isArray(state.errors.emailForRedirection)}
              helperText={
                _.isArray(state.errors.emailForRedirection) &&
                state.errors.emailForRedirection.join()
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={executeTest}
              variant="outlined"
              disabled={state.loading}
            >
              Execute
            </Button>
          </Grid>
          <Grid item xs={12}>
            {state.loadingUsers && tabTransitionEnded ? (
              <Grid
                container
                justifyContent={"center"}
                alignItems="center"
                style={{ height: "100%", maxHeight: 600 }}
              >
                <Grid item>
                  <CircularProgress size={50} />
                </Grid>
              </Grid>
            ) : state.users.length > 0 ? (
              <NotificationUsers
                users={state.users}
                selected={state.selected}
                toggleSelection={toggleSelection}
              />
            ) : (
              "No users"
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {state.loading && tabTransitionEnded ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            style={{ height: "100%", maxHeight: 600 }}
          >
            <Grid item>
              <CircularProgress size={100} />
            </Grid>
          </Grid>
        ) : state.result === "" ? (
          <Typography>No results to show</Typography>
        ) : (
          <div
            className={classes.htmlTemplate}
            dangerouslySetInnerHTML={{ __html: state.result }}
          />
        )}
      </Grid>
    </Grid>
  );
};

const NotificationUsers = ({ users, selected = {}, toggleSelection }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Grid container spacing={2} alignItems={"flex-start"}>
          <Grid item sm={1}></Grid>
          <Grid item sm={6}>
            User
          </Grid>
          <Grid item sm={2}>
            Active
          </Grid>
          <Grid item sm={2}>
            Last Login
          </Grid>
        </Grid>
      </Grid>
      {users.map((user, index) => (
        <Grid item key={index} sm={12}>
          <NotificationUser
            user={user}
            selected={selected}
            toggleSelection={toggleSelection}
          />
        </Grid>
      ))}
    </Grid>
  );
};
const NotificationUser = ({ user, selected = {}, toggleSelection }) => {
  const checked = selected ? selected.checked : false;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={1}>
        <Checkbox
          checked={checked}
          onClick={(event) => toggleSelection(user)}
        />
      </Grid>
      <Grid item sm={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <CustomAvatar
              text={user.fullName}
              picture={user.picture}
              size={"small"}
            />
          </Grid>
          <Grid
            item
            style={{
              flex: 1,
              minWidth: 0,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Typography variant="body2">{user.fullName}</Typography>
            <Typography variant="body2">{user.roleDescription}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={2}>
        {user.active ? (
          <GeCheckTrue fontSize={"small"} />
        ) : (
          <GeCheckFalse fontSize={"small"} />
        )}
      </Grid>
      <Grid item sm={2}>
        {user.lastLogin
          ? dayjs.utc(user.lastLogin).local().format("llll")
          : "-"}
      </Grid>
    </Grid>
  );
};

export default WelcomeMessageTest;
