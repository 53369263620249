import {
  Checkbox,
  CircularProgress,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useContext, useState } from "react";

import ErrorFound from "./ErrorFound";
import Filters from "../../General/Pagination/Filters";
import Grid from "@mui/material/Grid";

import { HomeAppContext } from "../../Home";
import NoEntriesFound from "../../General/Pagination/NoEntriesFound";
import PropTypes from "prop-types";
import StateIndicator from "../StateIndicator";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../General/Pagination/TablePaginationActions";
import _ from "lodash";
import api from "../../../Services/api";
import clsx from "clsx";
import { errorManagement } from "../../../Services/errorManagement";
import MyDataGridHeaders from "./MyDataGridHeaders";

const useStyles = makeStyles((theme) => ({
  divider: {
    paddingTop: "0!important",
    paddingBottom: "0!important"
  },
  entryZone: {
    transition: "0.2s filter linear"
  },
  loadingEffect: {
    filter: "opacity(0.5)",
    pointerEvents: "none"
  }
}));

const useStylesPagination = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end"
  }
}));

const RenderEntry = React.memo(
  ({ item, renderItem, selectionProperties, selectionCheckbox }) => {
    return renderItem(item, selectionProperties, selectionCheckbox);
  }
);

const RenderExtra = React.memo(({ extra, renderExtra }) => {
  return renderExtra ? renderExtra(extra) : null;
});

const RenderExternalError = React.memo(({ error }) => {
  return error ? (
    <TableRow>
      <TableCell colSpan={columnsLength}>
        <StateIndicator message={error.message} type={error.severity} />
      </TableCell>
    </TableRow>
  ) : null;
});

const RenderEntryWithCheckbox = React.memo(
  ({
    item,
    autoSelected,
    selected,
    excluded,
    filterOutCheck = null,
    toggleSelection,
    renderItem,
    error,
    columnsLength
  }) => {
    const classes = useStyles();
    const checked =
      (selected ? selected.checked : autoSelected || false) ||
      (excluded ?? false);
    const disabled = excluded;
    const filterOut = filterOutCheck !== null ? filterOutCheck(item) : false;

    return (
      <React.Fragment>
        <RenderEntry
          item={item}
          renderItem={renderItem}
          selectionProperties={disabled ? { filter: "opacity(0.5)" } : {}}
          selectionCheckbox={
            !filterOut ? (
              <Checkbox
                disabled={disabled}
                checked={checked}
                onClick={() => toggleSelection(item)}
              />
            ) : (
              <Checkbox
                disabled={true}
                style={{ visibility: "hidden" }}
                checked={true}
              />
            )
          }
        />
        {error && (
          <RenderExternalError error={error} columnsLength={columnsLength} />
        )}
      </React.Fragment>
    );
  }
);

const MyDataGrid = ({
  AddMultiEdit,
  RemoveMultiEdit,
  options,
  multiSelect = false,
  selectionId = "id",
  selectionPayload = ["id"],
  filterOutIds = [],
  autoSelectIds = [],
  filterOutCheck = null,
  onSelectedItemsChange = null,
  onItemsCountChange = null,
  onFiltersUpdate = null,
  url,
  urlExport = [],
  exportLabel = "Export",
  renderItem,
  renderExtra = null,
  externalErrors = null,
  refresh = 0,
  reload = 0,
  report = 0,
  label = "Items",
  autoFilter = true,
  onLoading = null,
  onPageLoaded = null,
  loading = false,
  rowsPerPageOptions = [5, 10, 20, 50, 100],
  rowsPerPage = 20,
  customRender = null,
  pagination = true,
  filtersSize = "normal",
  size = "medium",
  compactStatuses = false,
  maxHeight = 800
}) => {
  const { homeState, homeDispatch } = useContext(HomeAppContext);

  const classes = useStyles();
  const classesPagination = useStylesPagination();

  const [state, setState] = useState({
    firstLoad: true,
    reload: 0,
    pageLoading: false,
    error: false,
    errorMessage: "Something was not right...",
    page: 0,
    rowsPerPageOptions: rowsPerPageOptions,
    rowsPerPage: rowsPerPage,
    items: [],
    data: {},
    itemsCount: 0,
    extra: null,
    internalExport: 0,
    externalErrors:
      externalErrors !== null
        ? externalErrors.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
        : {},
    selectedItems:
      autoSelectIds.length !== 0
        ? autoSelectIds.reduce((acc, current) => {
            acc[current] = { checked: true, payload: { id: current } };
            return acc;
          }, {})
        : {},
    excludedItems: filterOutIds.reduce((acc, current) => {
      acc[current] = true;
      return acc;
    }, {}),
    multiSelectCheck: false,
    columns: options.columns,
    additionalFilters: options.additionalFilters,
    preFilters: options.preFilters ?? {},
    exportFilters: options.exportFilters ?? {},
    additionalPayload: options.additionalPayload ?? null,
    filters: {},
    waitForFilters: [
      ...options.columns
        .filter((x) => x.filter.enabled && x.filter.required)
        .map((x) => x.filter.name ?? x.name),
      ...options.additionalFilters
        .filter((x) => x.filter.enabled && x.filter.required)
        .map((x) => x.filter.name ?? x.name)
    ],
    filterComparison: {
      ...options.columns.reduce((obj, x) => {
        if (x.filter.enabled)
          obj[x.filter.name ?? x.name] = x.filter.comparison;
        return obj;
      }, {}),
      ...options.additionalFilters.reduce((obj, x) => {
        if (x.filter.enabled)
          obj[x.filter.name ?? x.name] = x.filter.comparison;
        return obj;
      }, {})
    },
    sort: options.defaultSort
      ? options.defaultSort.reduce((arr, x) => {
          arr = [...arr, { name: x.name, value: x.order }];
          return arr;
        }, [])
      : []
  });

  const lastItemIndex = state.items.length - 1;

  const showNoEntries =
    state.itemsCount === 0 && !state.pageLoading && !state.error;
  const showError = state.itemsCount === 0 && !state.pageLoading && state.error;

  const showResults = state.items.length !== 0 && !state.pageLoading;

  const exportEnabled = urlExport.length !== 0;
  const canExport = urlExport.length !== 0 && state.itemsCount !== 0;

  const setStateValues = (values) => {
    setState((prevState) => ({ ...prevState, ...values }));
  };

  const setFiltersValues = useCallback((values) => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      filters: { ...prevState.filters, ...values }
    }));
  }, []);

  const setSortValues = (values) => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      sort: values
    }));
  };

  const escapeValue = (value) => {
    return typeof value === "string"
      ? encodeURIComponent(value.replaceAll(",", "\\,"))
      : value;
  };

  const getFilters = useCallback(() => {
    let filters = [];
    for (const [key, filter] of Object.entries(state.preFilters)) {
      if (filter.value !== null && filter.value !== "")
        filters.push(key + filter.comparison + filter.value);
    }
    for (const [key, value] of Object.entries(state.filters)) {
      if (value !== null && value !== "") {
        filters.push(key + state.filterComparison[key] + escapeValue(value));
      }
    }
    const result = filters.join(", ");
    if (onFiltersUpdate !== null) onFiltersUpdate(result);
    return result;
  }, [
    onFiltersUpdate,
    state.filterComparison,
    state.filters,
    state.preFilters
  ]);

  const getExportFilters = useCallback(() => {
    let filters = [];
    for (const [key, filter] of Object.entries(state.exportFilters)) {
      if (filter.value !== null && filter.value !== "")
        filters.push(key + filter.comparison + filter.value);
    }
    for (const [key, filter] of Object.entries(state.preFilters)) {
      if (filter.value !== null && filter.value !== "")
        filters.push(key + filter.comparison + filter.value);
    }
    for (const [key, value] of Object.entries(state.filters)) {
      if (value !== null && value !== "")
        filters.push(key + state.filterComparison[key] + value);
    }
    const result = filters.join(", ");
    if (onFiltersUpdate !== null) onFiltersUpdate(result);
    return result;
  }, [
    onFiltersUpdate,
    state.exportFilters,
    state.filterComparison,
    state.filters,
    state.preFilters
  ]);

  const getSorts = useCallback(() => {
    const filters = state.sort.map((x) => x.value + x.name);
    return filters.join(",");
  }, [state.sort]);

  const handleChangePage = (event, newPage) => {
    setStateValues({ page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setStateValues({ page: 0, rowsPerPage: parseInt(event.target.value) });
  };

  const getItemPayload = useCallback(
    (item) =>
      selectionPayload.reduce((acc, x) => ({ ...acc, [x]: item[x] }), {}),
    [selectionPayload]
  );
  const columnSelectAll = useCallback(
    (event, name, language) => {
      console.log(event);
      var itemsToChange = state.items;
      itemsToChange.forEach((element) => {
        if (element.atmsJobId !== 0) {
          if (event) {
            AddMultiEdit({
              atmsJobId: element.atmsJobId
            });
          } else {
            RemoveMultiEdit({
              atmsJobId: element.atmsJobId
            });
          }

          element.multiEditSelected = event;
          element.selected = event;
        }
      });
      console.log(itemsToChange);
      setState((prevState) => ({
        ...prevState,
        items: itemsToChange
      }));
      console.log(state);
    },
    [filterOutCheck, filterOutIds, selectionId, getItemPayload]
  );

  const toggleSelectionAll = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      multiSelectCheck: !prevState.multiSelectCheck,
      selectedItems: {
        ...prevState.selectedItems,
        ...prevState.items.reduce((acc, current) => {
          const filterOut =
            filterOutCheck !== null ? filterOutCheck(current) : false;
          if (!filterOut && !filterOutIds.includes(current[selectionId]))
            acc[current[selectionId]] = {
              payload: getItemPayload(current),
              checked: !prevState.multiSelectCheck
            };
          return acc;
        }, {})
      }
    }));
  }, [filterOutCheck, filterOutIds, selectionId, getItemPayload]);

  const toggleSelection = useCallback(
    (item) => {
      setState((prevState) => ({
        ...prevState,
        selectedItems: {
          ...prevState.selectedItems,
          [item[selectionId]]:
            prevState.selectedItems[item[selectionId]] === undefined
              ? { payload: getItemPayload(item), checked: true }
              : {
                  payload: getItemPayload(item),
                  checked: !prevState.selectedItems[item[selectionId]].checked
                }
        }
      }));
    },
    [getItemPayload, selectionId]
  );

  const renderItemCallback = useCallback(renderItem, [renderItem]);
  const filterOutCheckCallback = useCallback(filterOutCheck, []);
  const renderExtraCallback = useCallback(renderExtra, []);
  const onLoadingCallback = useCallback(onLoading, []);
  const onPageLoadedCallback = useCallback(onPageLoaded, [onPageLoaded]);
  const customRenderCallback = useCallback(customRender, []);

  React.useEffect(() => {
    const getItems = async () => {
      setState((prevState) => ({
        ...prevState,
        pageLoading: true,
        error: false
      }));
      const currentPage = state.page + 1;
      const params = {
        page: currentPage,
        pageSize: state.rowsPerPage,
        sorts: getSorts(),
        filters: getFilters()
      };

      try {
        const response =
          state.additionalPayload === null
            ? await api.get(url, {
                params: params
              })
            : await api.post(url, {
                payload: state.additionalPayload,
                sieveModel: params
              });

        if (onItemsCountChange !== null)
          onItemsCountChange(response.data.count);

        if (
          currentPage > 1 &&
          response.data.count !== 0 &&
          response.data.items.length === 0
        ) {
          setState((prevState) => ({
            ...prevState,
            firstLoad: false,
            pageLoading: false,
            page: currentPage - 2
          }));
          return;
        }

        if (currentPage === 1) {
          setState((prevState) => ({
            ...prevState,
            pageLoading: false,
            firstLoad: false,
            items: response.data.items,
            itemsCount: response.data.count,
            extra: response.data.extra ?? null,
            data: response.data
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            pageLoading: false,
            firstLoad: false,
            items: response.data.items,
            itemsCount: response.data.count,
            data: response.data
          }));
        }
        if (onPageLoadedCallback !== null)
          onPageLoadedCallback(response.data.items, response.data.count);
      } catch (error) {
        const errorMessage =
          error?.response !== undefined &&
          _.isString(error.response.data) &&
          error.response.status !== 403
            ? error.response.data
            : "Something was not right...";

        setState((prevState) => ({
          ...prevState,
          pageLoading: false,
          firstLoad: false,
          error: true,
          errorMessage: errorMessage,
          items: [],
          data: {},
          itemsCount: 0,
          extra: null
        }));
        errorManagement.formErrors(error, homeDispatch);
      }
    };

    const presentFilters = Object.keys(state.filters);

    if (
      !loading &&
      (state.waitForFilters.length === 0 ||
        (state.waitForFilters.length !== 0 &&
          state.waitForFilters.every((x) => presentFilters.includes(x))))
    )
      getItems();
  }, [
    state.page,
    state.rowsPerPage,
    state.reload,
    state.filters,
    state.sort,
    state.waitForFilters,
    getSorts,
    getFilters,
    url,
    homeDispatch,
    loading,
    onPageLoadedCallback,
    state.additionalPayload,
    onItemsCountChange
  ]);

  React.useEffect(() => {
    if (externalErrors !== null) {
      const errors = Array.isArray(externalErrors)
        ? externalErrors.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
        : {};
      setState((prevState) => ({ ...prevState, externalErrors: errors }));
    }
  }, [externalErrors]);

  const onExport = (index = 0) => {
    console.log("export");
    console.log(urlExport[index]);
    let currentDate = new Date();
    const getItems = async () => {
      homeDispatch({
        type: "NOTIFICATION_REPORT",
        data: { message: "Preparing your report", loading: true }
      });

      setState((prevState) => ({
        ...prevState,
        pageLoading: true,
        error: false
      }));

      const params = {
        signalRConnectionId: homeState.connection.connectionId,
        selection:
          Object.keys(state.selectedItems).reduce((acc, current) => {
            if (state.selectedItems[current].checked) return [...acc, current];
            return acc;
          }, []) ?? [],
        sorts: getSorts(),
        filters: getExportFilters()
      };

      try {
        console.log(urlExport[index]);
        // await api.get(urlExport[index], {
        //   params: params
        // });
        var response = await api
          .get(urlExport[index], {
            params: params
          })
          .then((response) => {
            console.log(response);
            var binaryData = [];
            binaryData.push(response.data);
            const url = window.URL.createObjectURL(
              new Blob(binaryData, { type: "application/text" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "AtmsTrackerExport_" + currentDate + ".csv"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            homeDispatch({
              type: "NOTIFICATION",
              data: {
                message: "Export Downloaded",
                type: "success",
                loading: false
              }
            });
          })
          .catch((error) => {
            homeDispatch({
              type: "NOTIFICATION",
              data: {
                message:
                  "Generating export file failed failed: " + error.message,
                type: "error"
              }
            });
            //setLoading(false);
          });

        // homeDispatch({
        //   type: "NOTIFICATION",
        //   data: { close: true }
        // });
        // homeDispatch({
        //   type: "NOTIFICATION",
        //   data: { close: true }
        // });

        setState((prevState) => ({
          ...prevState,
          pageLoading: false,
          error: false
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          pageLoading: false,
          error: false
        }));
        errorManagement.formErrors(error, homeDispatch);
      }
    };

    if (urlExport[index]) getItems();
  };

  const onSelectedItemsChangeCallback = useCallback(onSelectedItemsChange, []);

  React.useEffect(() => {
    if (onSelectedItemsChangeCallback !== null) {
      onSelectedItemsChangeCallback(
        Object.keys(state.selectedItems).reduce((acc, current) => {
          if (state.selectedItems[current].checked)
            return [...acc, state.selectedItems[current].payload];
          return acc;
        }, [])
      );
    }
  }, [onSelectedItemsChangeCallback, state.selectedItems]);

  const refreshItems = () => {
    setState((prevState) => ({
      ...prevState,
      reload: prevState.reload + 1
    }));
  };

  const refreshItemsExternal = () => {
    setState((prevState) => ({
      ...prevState,
      multiSelectCheck: false,
      selectedItems: {},
      reload: prevState.reload + 1
    }));
  };

  React.useEffect(() => {
    if (refresh > 0) {
      refreshItemsExternal();
    }
  }, [refresh]);

  const reloadItemsExternal = () => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      multiSelectCheck: false,
      selectedItems: {},
      reload: prevState.reload + 1
    }));
  };

  React.useEffect(() => {
    if (reload > 0) {
      reloadItemsExternal();
    }
  }, [reload]);

  React.useEffect(() => {
    if (onLoadingCallback !== null) {
      onLoadingCallback(state.pageLoading);
    }
  }, [onLoadingCallback, state.pageLoading]);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Filters
          columns={state.columns}
          additionalFilters={state.additionalFilters}
          filters={state.filters}
          setFiltersValues={setFiltersValues}
          autoFilter={autoFilter}
          urlExport={urlExport}
          exportEnabled={exportEnabled}
          canExport={canExport}
          onExport={onExport}
          exportLabel={exportLabel}
          loading={state.pageLoading}
          size={filtersSize}
        />
      </Grid>
      {customRenderCallback ? (
        <React.Fragment>
          <Grid
            item
            xs={12}
            style={{
              position: "fixed",
              top: "50%",
              left: "calc(50% + 50px)",
              zIndex: 5,
              display:
                state.pageLoading && state.items.length === 0 ? "block" : "none"
            }}
          >
            <Grid container justifyContent={"center"}>
              <CircularProgress size={100} />
            </Grid>
          </Grid>
          {state.itemsCount ? customRenderCallback(state.data) : null}
        </React.Fragment>
      ) : (
        <Grid item sm={12}>
          <TableContainer>
            <Table
              stickyHeader
              style={{
                borderCollapse: "separate",
                maxHeight: maxHeight,
                display: "block",
                overflowX: "auto"
              }}
              size={size}
            >
              <TableHead>
                <MyDataGridHeaders
                  columns={state.columns}
                  sortValues={state.sort}
                  setSortValues={setSortValues}
                  multiSelect={multiSelect}
                  multiSelectCheck={state.multiSelectCheck}
                  handleSelectToggle={toggleSelectionAll}
                  columnSelectToggle={columnSelectAll}
                />
                {state.extra && state.items.length !== 0 ? (
                  multiSelect ? (
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          disabled={true}
                          style={{ visibility: "hidden" }}
                          checked={true}
                        />
                      </TableCell>
                      <TableCell>
                        <RenderExtra
                          extra={state.extra}
                          renderExtra={renderExtraCallback}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <RenderExtra
                          extra={state.extra}
                          renderExtra={renderExtraCallback}
                        />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableHead>
              <TableBody
                className={
                  showResults
                    ? classes.entryZone
                    : clsx(classes.entryZone, classes.loadingEffect)
                }
              >
                {multiSelect
                  ? state.items.map((item, index) => (
                      <RenderEntryWithCheckbox
                        key={index}
                        item={item}
                        autoSelected={autoSelectIds.includes(item[selectionId])}
                        selected={state.selectedItems[item[selectionId]]}
                        excluded={state.excludedItems[item[selectionId]]}
                        error={state.externalErrors[item[selectionId]]}
                        toggleSelection={toggleSelection}
                        renderItem={renderItemCallback}
                        filterOutCheck={filterOutCheckCallback}
                        columnsLength={state.columns.length}
                      />
                    ))
                  : state.items.map((item, index) => (
                      <React.Fragment key={index}>
                        <RenderEntry
                          item={item}
                          renderItem={renderItemCallback}
                        />
                        <RenderExternalError
                          error={state.externalErrors[item[selectionId]]}
                          columnsLength={state.columns.length}
                        />
                      </React.Fragment>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{
          display:
            state.pageLoading && state.items.length === 0 ? "block" : "none"
        }}
      >
        <Grid container justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      </Grid>
      <Fade
        in={showNoEntries && !state.firstLoad}
        style={{
          display: showNoEntries && !state.firstLoad ? "block" : "none"
        }}
      >
        <Grid item xs={12}>
          <NoEntriesFound
            message={`No ${label} found`}
            onRefresh={refreshItems}
            compact={compactStatuses}
          />
        </Grid>
      </Fade>

      <Fade
        in={!autoFilter && !loading && showNoEntries && state.firstLoad}
        style={{
          display: showNoEntries && state.firstLoad ? "block" : "none"
        }}
      >
        <Grid item xs={12}>
          <NoEntriesFound
            message={`Click the "Search" button to filter ${label}`}
            compact={compactStatuses}
          />
        </Grid>
      </Fade>
      <Fade
        in={showError}
        style={{
          display: showError ? "block" : "none"
        }}
      >
        <Grid item xs={12}>
          <ErrorFound message={state.errorMessage} />
        </Grid>
      </Fade>
      {pagination && !state.firstLoad && state.itemsCount !== 0 && (
        <Grid item xs={12}>
          <TablePagination
            classes={{
              toolbar: classesPagination.toolbar
            }}
            component={Grid}
            rowsPerPageOptions={state.rowsPerPageOptions}
            colSpan={4}
            count={state.itemsCount}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            labelRowsPerPage={`${capitalize(label)} per page:`}
            SelectProps={{
              inputProps: { "aria-label": `${capitalize(label)} per page` },
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={(props) => (
              <TablePaginationActions
                {...props}
                loading={state.pageLoading}
                onRefresh={refreshItems}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

MyDataGrid.propTypes = {
  options: PropTypes.object,
  multiSelect: PropTypes.bool,
  selectionId: PropTypes.string,
  filterOutIds: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
  url: PropTypes.string.isRequired,
  renderItem: PropTypes.func,
  refresh: PropTypes.number,
  label: PropTypes.string,
  autoFilter: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"])
};

export default MyDataGrid;
