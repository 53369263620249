import { Badge, Checkbox, TableCell, TableRow } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  divider: {
    paddingTop: "0!important",
    paddingBottom: "0!important"
  },
  mainHeader: {
    paddingTop: "5px!important"
  },
  header: {
    fontWeight: "bold"
  },
  sortable: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main
    }
  },
  headers: {
    minHeight: 34
  }
}));

const SortBadge = withStyles((theme) => ({
  badge: {
    transform: "scale(0.8)",
    right: 4,
    top: -12,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "white",
    color: theme.palette.primary.main,
    padding: "0 4px"
  },
  root: {
    height: 20
  }
}))(Badge);

const Icon = (sort, order) => {
  return (
    <React.Fragment>
      <SortBadge badgeContent={order} color="primary">
        {sort.value === "" ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </SortBadge>
    </React.Fragment>
  );
};

const RenderSort = ({ column, sortValues }) => {
  const sort = sortValues.find((x) => x.name === column.name);
  const order =
    sortValues.length === 1
      ? 0
      : sortValues.findIndex((x) => x.name === column.name) + 1;
  return sort ? Icon(sort, order) : null;
};

const MyDataGridHeaders = ({
  columns,
  sortValues,
  setSortValues,
  multiSelect = false,
  multiSelectOnlyOne,
  multiSelectCheck,
  handleSelectToggle,
  columnSelectToggle
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const setSort = (event, column) => {
    const sort = sortValues.find((x) => x.name === column.name);
    if (sort) {
      if (sort.value === "") {
        setSortValues(
          sortValues.map((x) =>
            x.name === column.name ? { ...x, value: "-" } : x
          )
        );
      } else {
        setSortValues(sortValues.filter((x) => x.name !== column.name));
      }
    } else {
      setSortValues([...sortValues, { name: column.name, value: "" }]);
    }
  };

  const isString = (x) => {
    return Object.prototype.toString.call(x) === "[object String]";
  };

  const addPreviousPxSizes = (array, index) => {
    return (
      array
        .slice(0, index)
        .reduce((acc, x) => acc + x.size.replace("px", "") * 1, 0) + "px"
    );
  };
  const renderHeaders = () => {
    return (
      <React.Fragment>
        {columns.map((column, index, array) => (
          <TableCell
            {...(column.size === "" || column.size === undefined
              ? {
                  style: {
                    textAlign: column.align || "left"
                  }
                }
              : column.size === "auto"
              ? {
                  style: {
                    textAlign: column.align || "left"
                  }
                }
              : isString(column.size) &&
                (column.size.includes("%") || column.size.includes("px"))
              ? {
                  style: {
                    textAlign: column.align || "left",
                    minWidth: column.size,
                    ...(column.sticky
                      ? {
                          position: "sticky",
                          left:
                            index === 0
                              ? "0px"
                              : addPreviousPxSizes(array, index),
                          backgroundColor: theme.palette.background.default,
                          zIndex: 2
                        }
                      : {})
                  }
                }
              : {
                  style: {
                    textAlign: column.align || "left",
                    minWidth: (column.size * 100) / 12
                  }
                })}
            key={index}
          >
            {column.isCheckbox && (
              <Checkbox
                style={{ marginLeft: 0 }}
                onClick={(event) =>
                  columnSelectToggle(
                    event.target.checked,
                    column.name,
                    column.columnLanguage
                  )
                }
                disabled={multiSelectOnlyOne}
                //style={multiSelectOnlyOne ? { visibility: "hidden" } : {}}
              />
            )}
            <Box
              component="div"
              display="inline"
              className={
                column.sortable
                  ? clsx(classes.header, classes.sortable)
                  : classes.header
              }
              onClick={
                column.sortable ? (event) => setSort(event, column) : null
              }
            >
              {column.description}
              {column.sortable ? (
                <RenderSort column={column} sortValues={sortValues} />
              ) : null}
            </Box>
          </TableCell>
        ))}
      </React.Fragment>
    );
  };

  return (
    <TableRow className={classes.mainHeader}>
      {multiSelect ? (
        <React.Fragment>
          <TableCell>
            <Checkbox
              checked={multiSelectCheck}
              onClick={handleSelectToggle}
              disabled={multiSelectOnlyOne}
              style={multiSelectOnlyOne ? { visibility: "hidden" } : {}}
            />
          </TableCell>
          {renderHeaders()}
        </React.Fragment>
      ) : (
        <React.Fragment>{renderHeaders()}</React.Fragment>
      )}
    </TableRow>
  );
};

export default MyDataGridHeaders;
