import React, { useCallback, useContext, useState } from "react";

import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./Form";
import { HomeAppContext } from "../../../Home";
import Slide from "@mui/material/Slide";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, onEdit }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [errors, setErrors] = useState([]);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const getEntry = useCallback(() => {
    setLoading(true);
    api
      .get(`/api/system/settings`)
      .then((r) => {
        setInternalEntry({
          ...r.data
        });
        setErrors([]);
        setLoading(false);
        setSubmitted(false);
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  }, [homeDispatch]);

  React.useEffect(() => {
    if (open) getEntry();
  }, [getEntry, open]);

  const handleClose = () => {
    setOpen(false);
    setInternalEntry(null);
    setLoading(true);
  };

  const handleCloseSave = () => {
    const updateEntry = {
      ...internalEntry
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating system settings", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`/api/system/settings`, updateEntry)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "System settings updated", type: "success" }
        });
        setOpen(false);
        setInternalEntry(null);
        setLoading(false);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">
        {"Edit System settings"}
      </DialogTitle>
      <DialogContent>
        {internalEntry !== null ? (
          <Form
            entry={internalEntry}
            errors={errors}
            setEntry={setInternalEntry}
            loading={loading}
            submitted={submitted}
            setSaveDisabled={setSaveDisabled}
          />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
