import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slide,
  Typography,
} from "@mui/material";

import Button from "@mui/material/Button";
import React from "react";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({
  open,
  setOpen,
  title = "Alert dialog",
  message = "",
  notes = null,
  reconfirmMessage = "Yes, I´m sure",
  withReconfirm = true,
  confirmText = "confirm",
  onClose = null,
  onCloseSuccess = null,
  successParams = null,
}) {
  const [reconfirm, setReconfirm] = React.useState(!withReconfirm);

  React.useEffect(() => {
    if (open) setReconfirm(!withReconfirm);
  }, [open, withReconfirm]);

  const handleClose = () => {
    setOpen(false);
    if (onClose !== null) onClose();
  };

  const handleCloseSuccess = () => {
    if (onCloseSuccess !== null) {
      if (successParams !== null) {
        onCloseSuccess(successParams);
      } else {
        onCloseSuccess();
      }
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-reset-title"
    >
      <DialogTitle id="alert-dialog-reset-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography gutterBottom>{message}</Typography>
          </Grid>
          {withReconfirm && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={reconfirm}
                    onChange={(event) => setReconfirm(event.target.checked)}
                  />
                }
                label={reconfirmMessage}
              />
            </Grid>
          )}
          {notes !== null && (
            <Grid item>
              <Alert severity={"info"}>
                <Typography variant="body2" gutterBottom>
                  {notes}
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleCloseSuccess}
          color="primary"
          autoFocus
          disabled={withReconfirm && !reconfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
