import { Avatar, Box, Collapse, TableCell, TableRow, Tooltip } from "@mui/material";
import { dayjs } from "../../Services/dateUtil";
import BuildIcon from "@mui/icons-material/Build";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TabularField from "../General/TabularField";


const useStyles = makeStyles((theme) => ({
  Information: {
    color: "#fff",
    backgroundColor: "#2196f3"
  },
  Warning: {
    color: "#fff",
    backgroundColor: "#ff9800"
  },
  Error: {
    color: "#fff",
    backgroundColor: "#f44336"
  },
  Fatal: {
    color: "#fff",
    backgroundColor: "black"
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  systemIcon: {
    color: theme.palette.secondary.main,
    backgroundColor: "white"
  },
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const Entry = ({ entry }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow((show) => !show);
  };
  const formatText = (string) =>
    string
      ? string.split("\\n").map((item, key) => {
        return (
          <span key={key}>
              {item}
            <br />
            </span>
        );
      })
      : "";
  const parProperties = (string) => {
    try {
      const json = JSON.parse(string);
      return formatText(JSON.stringify(json, null, "\t"));
    } catch (e) {
      return string;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{"&>td":{borderBottomStyle:"none"}}}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {dayjs(entry.timestamp).utc("z").local().format("YYYY-MM-DD, HH:mm:ss")}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <Chip
            className={classes[entry.level]}
            size="small"
            label={entry.level}
          />
        </TableCell>
        <TableCell>
          {entry.user !== null ? (
            <Tooltip title={entry.user.fullName}>
              <Avatar
                className={classes.small}
                src={entry.user.picture}
                alt={entry.user.fullName}
              />
            </Tooltip>
          ) : (
            <Tooltip title={"System"}>
              <Avatar className={classes.small}>
                <BuildIcon className={classes.systemIcon} />
              </Avatar>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {entry.message}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0}} colSpan={4}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <TabularField
                    label={"Local time"}
                    labelGridSize={1}
                    value={dayjs(entry.timestamp).utc("z")
                      .local()
                      .format("YYYY-MM-DD, HH:mm:ss")}
                  />
                  <TabularField
                    label={"UTC time"}
                    labelGridSize={1}
                    value={dayjs(entry.timestamp).utc("z")
                      .format("YYYY-MM-DD, HH:mm:ss.SSS")}
                  />
                  {entry.exception !== null &&
                    <TabularField
                      label={"Exception"}
                      labelGridSize={1}
                      value={
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {formatText(entry.exception)}
                        </div>
                      }
                    />
                  }
                  <TabularField
                    label={"Information"}
                    labelGridSize={1}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.properties)}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
