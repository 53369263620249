import { CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";

import EmailLogStatus from "./EmailLogStatus";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import { dayjs } from "../../../../Services/dateUtil";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  Information: {
    color: "#fff",
    backgroundColor: "#2196f3"
  },
  Warning: {
    color: "#fff",
    backgroundColor: "#ff9800"
  },
  Error: {
    color: "#fff",
    backgroundColor: "#f44336"
  },
  Fatal: {
    color: "#fff",
    backgroundColor: "black"
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  systemIcon: {
    color: theme.palette.secondary.main,
    backgroundColor: "white"
  },
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const RenderField = ({ label, value }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={4}>
        <b>{label}</b>
      </Grid>
      <Grid item sm={8}>
        {value}
      </Grid>
    </Grid>
  );
};
const RenderDestination = ({ label, value }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={1}>
        <b>{label}</b>
      </Grid>
      <Grid item sm={11}>
        {value
          .map((address) => `${address.name} <${address.email}>`)
          .join(", ")}
      </Grid>
    </Grid>
  );
};

const RenderDestinations = ({ entry }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={12}>
        <RenderDestination label="To" value={entry.to} />
      </Grid>
      {entry.bcc.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Bcc" value={entry.bcc} />
        </Grid>
      )}
      {entry.cc.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Cc" value={entry.cc} />
        </Grid>
      )}
      {entry.replyTo.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Reply to" value={entry.replyTo} />
        </Grid>
      )}
    </Grid>
  );
};

const RenderFields = ({ entry }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={12}>
        <RenderField label="Subject" value={entry.subject} />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Embed images"
          value={
            entry.embedImages.length !== 0 ? entry.embedImages.join() : "none"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Attachments"
          value={
            entry.attachments.length !== 0 ? entry.attachments.join() : "none"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Created at"
          value={dayjs.utc(entry.createdAt).local().format("lll")}
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Status"
          value={
            <EmailLogStatus value={entry.status} retries={entry.retries} />
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Sent at"
          value={
            entry.sentAt !== null
              ? dayjs.utc(entry.sentAt).local().format("lll")
              : "-"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Last Retry"
          value={
            entry.lastRetry !== null
              ? dayjs.utc(entry.lastRetry).local().format("lll")
              : "-"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField label="Retries" value={entry.retries} />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Server Response"
          value={entry.serverResponse !== null ? entry.serverResponse : "-"}
        />
      </Grid>
    </Grid>
  );
};

const EntryDetails = ({ entry }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [entryData, setEntryData] = useState(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (entry !== null)
      api
        .get(`/api/system/mail-logs/${entry.id}/details`)
        .then((r) => {
          setEntryData(r.data);
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        });
  }, [entry, homeDispatch]);

  return entryData !== null ? (
    <Grid container spacing={4} className={classes.Details}>
      <Grid item sm={12}>
        <RenderDestinations entry={entry} />
      </Grid>
      <Grid item sm={5}>
        <RenderFields entry={entry} />
      </Grid>
      <Grid item sm={7}>
        <Box
          sx={(theme) => ({
            "& .myTable": {
              color: theme.palette.mode === "dark" ? "black" : "inherit"
            }
          })}
          dangerouslySetInnerHTML={{ __html: entryData.body }}
        />
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default EntryDetails;
