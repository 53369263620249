import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";

import EmailLogStatus from "./EmailLogStatus";
import EntryDetails from "./EntryDetails";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../../../Services/dateUtil";
import Box from "@mui/material/Box";

const Entry = ({ entry }) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow((show) => !show);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {entry.to.length === 1 ? (
                <React.Fragment>
                  <Typography variant="body2">{entry.to[0].name}</Typography>
                  <Typography variant="caption">{entry.to[0].email}</Typography>
                </React.Fragment>
              ) : (
                "Multiple addresses"
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{entry.subject}</TableCell>
        <TableCell>
          {dayjs.utc(entry.createdAt).local().format("lll")}
        </TableCell>
        <TableCell>
          {entry.sentAt !== null
            ? dayjs.utc(entry.sentAt).local().format("lll")
            : "-"}
        </TableCell>
        <TableCell>
          <EmailLogStatus value={entry.status} retries={entry.retries} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Grid container alignItems={"center"}>
            <Grid item sm={12}>
              <Collapse in={show} mountOnEnter unmountOnExit>
                <Box
                  sx={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 40,
                    paddingRight: 20
                  }}
                >
                  <EntryDetails entry={entry} />
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
